// =============================================================================
// Form
// =============================================================================

form {
  margin: 0;

  label,
  .faux-label {
    font-weight: 700;
    font-size: .9375rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 4px; // Small amount of margin radio/checkbox

    .label-subtext {
      font-size: 90%;
      font-weight: 500;
      letter-spacing: normal;
      opacity: .8;
      text-transform: none;
    }
  }

  [type="radio"] + label,
  [type="checkbox"] + label {
    color: inherit;
    font-weight: inherit;
    font-size: 1rem;
    letter-spacing: inherit;
    margin-left: 10px;
    position: relative;
    text-transform: inherit;
    top: 1px;
  }
}

.input {
  margin-bottom: $element-spacing-unit / 1.75;
  max-width: 31.375rem;

  select {
    max-width: 100%;
  }

  &--full-width {
    max-width: none;
  }

  &--text {
    color: $text-input;
    font-size: 1.125rem;
    border: 1px solid lighten($green-2018, 30%);
    padding: 12px 40px 12px 20px;
    margin-top: 4px;
    width: 100%;

    &::placeholder {
      font-style: italic;
    }

    &:focus {
      box-shadow: 0 1px 10px rgba($green, 1);
      outline: none;
    }
  }

  &--error {
    label,
    .faux-label {
      color: red;
    }
  }
}

.input-feedback {
  @extend .hidden;
  font-size: 1rem;
  padding-top: 5px;

  .input--error & {
    color: red;
    display: block !important;
    visibility: visible;
  }
}

.has-icon {
  background-repeat: no-repeat;
  background-position: 8px center;
  padding-left: 45px !important;

  &--twitter {
    background-image: url('/lib/svg/ico-twitter.svg');
  }

  &--facebook {
    background-image: url('/lib/svg/ico-facebook.svg');
    background-position: 11px center;
  }

  &--instagram {
    background-image: url('/lib/svg/ico-instagram.svg');
  }

  &--linkedin {
    background-image: url('/lib/svg/ico-linkedin.svg');
  }
}
