.pg-home,
.pg-landing {
  .js-fade-in {
    transition: all 1000ms;

    &--is-hidden {
      opacity: 0;
      transform: translateY(35px);
    }
  }
}
