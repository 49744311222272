// .figure-boxes {

// }

.figure-box {

  // Apply vertical whitespace between stacked .figure-box'es
  .grid__item:not(:first-child) & {
    padding-top: $grid-spacing-unit;
  }

  // Undo the above on specific grid-types at a specific breakpoint
  .figure-boxes--home-ctas .grid__item:not(:first-child) &,
  .figure-boxes--2-col .grid__item:not(:first-child) & {
    @include mq-min-width($bp-lap) {
      padding-top: 0;
    }
  }

  // Undo the above on specific grid-types at a specific breakpoint
  .figure-boxes--3-col .grid__item:not(:first-child) & {
    @include mq-min-width($bp-lap-lg) {
      padding-top: 0;
    }
  }

  .figure-boxes--home-ctas & {
    @include mq-min-width($bp-desk-plus) {
      position: relative;
    }
  }

  figcaption {
    padding-top: ($grid-spacing-unit / 2);

    // The 'home page only' huge boxes (call-to-actions).
    .figure-boxes--home-ctas & {
      @include mq-min-width($bp-desk-plus) {
        background-color: #fff;
        bottom: 0;
        padding: $grid-spacing-unit;
        position: absolute;
        width: calc(100% - 66px);
      }

      @include mq-min-width($bp-desk-lg) {
        width: calc(100% - 140px);
        padding: ($grid-spacing-unit * 1.325) ($grid-spacing-unit * 1.625);
      }
    }

    // Slight alignment tweak  for the second/right 'home page only' huge box (call-to-action).
    .figure-boxes--home-ctas .grid__item:nth-child(2) & {
      @include mq-min-width($bp-desk-plus) {
        right: 0;
      }
    }

    // The standard 3-col boxes.
    .figure-boxes--3-col & {
      @include mq-min-width($bp-desk-mid) {
        background-color: #fff;
        margin-left: 66px;
        margin-top: -100px;
        padding: $grid-spacing-unit;
        padding-bottom: 0;
        position: relative;
      }
    }

    // The standard 2-col boxes.
    .figure-boxes--2-col & {
      @include mq-min-width($bp-desk-plus) {
        background-color: #fff;
        margin-left: 66px;
        margin-top: -100px;
        padding: $grid-spacing-unit;
        padding-bottom: 0;
        position: relative;
      }
    }

    // The standard 1-col box.
    .figure-boxes--1-col & {
      @include mq-min-width($bp-lap-lg) {
        background-color: #fff;
        margin-left: 66px;
        margin-top: -100px;
        padding: $grid-spacing-unit;
        padding-bottom: 0;
        position: relative;
      }

      @include mq-min-width($bp-desk-plus) {
        margin-left: 33.333%;
      }
    }

    h2 {
      font-family: $font-stack-omnes;
      font-size: (24rem/16);
      font-weight: 500;
      margin-bottom: 0.4em;

      @include mq-min-width($bp-lap-lg) {
        font-size: (30rem/16);
      }

      .figure-boxes--home-ctas & {
        @include mq-min-width($bp-desk-lg) {
          font-size: (40rem/16);
        }
      }

      b {
        color: $red-2018;
        font-weight: 500;
      }
    }

    p {
      &:last-child {
        margin-bottom: 0;

        a {
          font-size: (15rem/16);
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
}