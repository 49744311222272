.hero-grid {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  @include mq-min-width($bp-lap-lg) {
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
  }

  &__col-1 {
    @include mq-min-width($bp-lap-lg) {
      flex-basis: 70%;
    }

    @include mq-min-width(1200px) {
      flex-basis: 78%;
    }
  }

  &__col-2 {
    display: flex;

    @include mq-min-width($bp-lap-lg) {
      flex-direction: column;
      flex-basis: 30%;
    }

    @include mq-min-width(1200px) {
      flex-basis: 22%;
    }
  }

  &__box {
    background-size: cover;

    // All box paragraphs:
    p {
      margin: 0;
    }

    // Only the main box type:
    &--main {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 300px;
      padding: 0 ($grid-spacing-unit / 2) $grid-spacing-unit;

      @include mq-min-width($bp-palm-lg) {
        height: 400px;
      }

      @include mq-min-width($bp-lap) {
        height: 475px;
        padding-bottom: ($grid-spacing-unit * 2);
        padding-left: $grid-spacing-unit;
        padding-right: $grid-spacing-unit;
      }

      @include mq-min-width($bp-lap-lg) {
        height: 600px;
        padding-bottom: ($grid-spacing-unit * 3);
      }

      @include mq-min-width($bp-desk-plus) {
        padding-bottom: ($grid-spacing-unit * 4);
        padding-left: ($grid-spacing-unit * 2);
      }

      @include mq-min-width(1200px) {
        height: 730px;
      }

      h2 {
        color: #fff;
        font-family: $font-stack-omnes;
        font-size: (30rem/16);
        font-weight: 500;
        text-shadow: 4px 4px 18px rgba(0,0,0,0.3);

        @include mq-min-width($bp-lap) {
          font-size: (42rem/16);
        }

        @include mq-min-width($bp-lap-lg) {
          font-size: (30rem/16);
        }

        @include mq-min-width($bp-desk) {
          font-size: (42rem/16);
        }

        @include mq-min-width(1200px) {
          font-size: (56rem/16);
        }
      }
    }

    // Both alt box types:
    &--alt-1,
    &--alt-2 {
      flex-basis: 50%;
      position: relative;

      &::before,
      &::after {
        background-size: cover;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }

      a {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-family: $font-stack-omnes;
        font-size: (22rem/16);
        font-weight: 500;
        height: 200px;
        padding: 0 ($grid-spacing-unit / 2) $grid-spacing-unit;
        line-height: 1.143;
        position: relative;
        z-index: 2;

        @include mq-min-width($bp-palm-lg) {
          height: 275px;
        }

        @include mq-min-width($bp-lap) {
          font-size: (26rem/16);
          padding-left: $grid-spacing-unit;
          padding-right: $grid-spacing-unit;
        }

        @include mq-min-width($bp-lap-lg) {
          font-size: (22rem/16);
          height: 300px;
        }

        @include mq-min-width($bp-desk) {
          font-size: (26rem/16);
        }

        @include mq-min-width(1200px) {
          font-size: (30rem/16);
          height: 365px;
        }
      }
    }
  }

  &__text-row {
    display: block;
  }

  &__verbs {
    color: $beige-2018;
    display: inline-block;
    position: relative;
    vertical-align: top;

    span {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      white-space: nowrap;
    }

    .is-visible {
      opacity: 1;
      top: 0;
    }
  }

  &__call-out {
    background-color: #fff;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding: ($grid-spacing-unit / 2) ($grid-spacing-unit / 2) 0;

    @include ie-11-only {
      flex-basis: auto;
      width: 100%;
    }

    @include mq-min-width($bp-lap) {
      align-items: center;
      flex-direction: row;
      padding: $grid-spacing-unit $grid-spacing-unit 0;
      position: relative;
    }

    @include mq-min-width($bp-lap-lg) {
      flex-basis: calc(70% - #{$grid-spacing-unit});
      margin-left: $grid-spacing-unit;
      margin-top: -($grid-spacing-unit);
      padding: ($grid-spacing-unit / 2) ($grid-spacing-unit / 2) 0;

      @include ie-11-only {
        flex-basis: auto;
        margin-right: 30%;
        width: 100%;
      }
    }

    @include mq-min-width($bp-desk) {
      padding: $grid-spacing-unit $grid-spacing-unit 0;
    }

    @include mq-min-width($bp-desk-plus) {
      margin-top: -($grid-spacing-unit * 1.25);
      padding: ($grid-spacing-unit * 1.25) ($grid-spacing-unit * 1.5) 0;
    }

    @include mq-min-width(1200px) {
      flex-basis: calc(78.13% - #{$grid-spacing-unit});

      @include ie-11-only {
        flex-basis: auto;
        margin-right: 21.87%;
        width: 100%;
      }
    }

    > div {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      margin-bottom: ($grid-spacing-unit / 2);

      @include mq-min-width($bp-lap) {
        margin-bottom: 0;
        margin-right: ($grid-spacing-unit / 1.5);
      }
    }

    svg {
      flex-shrink: 0;
      height: 33px;
      width: 33px;
    }

    h3, p {
      font-size: (15rem/16);
      margin: 0;
    }

    h3 {
      color: $red-2018;
      margin-left: ($grid-spacing-unit / 3);
      text-transform: uppercase;
    }

    p {
      a {
        font-weight: 500;
      }
    }
  }
}