.loading-animation {
  height: 40px;
  margin: $grid-spacing-unit 0;
  position: relative;

  &__spinner {
    animation: rotateRct 1.2s ease-in-out infinite,
               colorChange 1.3s ease-in-out infinite;
    background-color: blue;
    bottom: 0;
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
  }

  &__text {
    font-weight: 700;
    padding-top: 10px;
    text-align: center;
  }

  .no-cssanimations & {
    &__spinner {
      display: none;
      visibility: hidden;
    }
  }

  .cssanimations & {
    &__text {
      display: none;
      visibility: hidden;
    }
  }

  @include mq-min-width($bp-lap) {
    margin-bottom: ($grid-spacing-unit * 3);
    margin-top: ($grid-spacing-unit * 3);
  }
}

@keyframes colorChange {
  0% {
    background: $green-2018;
  }
  33% {
    background: $green-light;
  }
  66% {
    background: $beige-light;
  }
  100% {
    background: $brown;
  }
}

@keyframes rotateRct {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
