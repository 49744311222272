// =============================================================================
// Breadcrumbs
// =============================================================================

.breadcrumbs {
  display: none;
  
  @include mq-min-width($bp-lap-lg) {
    margin-top: $grid-spacing-unit;
    padding-left: $grid-spacing-unit / 2;
  }
  @include mq-min-width($bp-desk-mid) {
    padding-left: $grid-spacing-unit;
  }

  > li {
    margin-right: 4px;
  }
}
.breadcrumbs > li + li:before{
  content:"\002F" "\00A0";
}
