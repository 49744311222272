.news {
  padding-bottom: $grid-spacing-unit / 1.5;

  @include mq-min-width($bp-lap-lg) {
    padding-bottom: $grid-spacing-unit;
  }

  @include mq-min-width($bp-desk) {
    padding-bottom: $grid-spacing-unit * 1.5;
  }

  @include mq-min-width($bp-desk-plus) {
    padding-bottom: $grid-spacing-unit * 2;
  }

  &__section + &__section {
    margin-top: $grid-spacing-unit;

    @include mq-min-width($bp-desk) {
      margin-top: $grid-spacing-unit * 1.5;
    }

    @include mq-min-width($bp-desk-plus) {
      margin-top: $grid-spacing-unit * 2;
    }
  }

  &__section-name {
    border-bottom: 1px solid rgba(85,75,74,.1);
    margin: 0;
    padding-bottom: $grid-spacing-unit / 2.5;

    @include mq-min-width($bp-desk-plus) {
      padding-bottom: ($grid-spacing-unit / 2);
    }

    @include mq-min-width($bp-desk-lg) {
      padding-bottom: ($grid-spacing-unit / 1.5);
    }
  }

  &__items {
    margin: 0;
    padding-left: 0;
  }

  &__item {
    border-bottom: 1px solid rgba(85,75,74,.1);
    list-style-type: none;
    padding: ($grid-spacing-unit / 2.5) 0;

    &:last-child {
      border-bottom: none;
    }

    @include mq-min-width($bp-desk) {
      padding: ($grid-spacing-unit / 1.5) 0;
    }
  }

  $date-width: 95px;

  &__date {
    color: $brown-dark;
    font-size: (13rem/16);
    margin-bottom: ($grid-spacing-unit / 2.5);
    text-transform: uppercase;

    @include mq-min-width($bp-desk-lg) {
      float: left;
      padding-top: 8px;
      width: $date-width;
    }
  }

  &__title {
    line-height: 1.3;
    margin-bottom: 0;

    a {
      font-weight: 500;
    }

    @include mq-min-width($bp-desk-lg) {
      margin-left: $date-width;
    }
  }



  &__brief {
    margin-bottom: 0;
    padding-top: ($grid-spacing-unit / 3);

    @include mq-min-width($bp-desk-lg) {
      margin-left: $date-width;
    }
  }
}
