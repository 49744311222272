// =============================================================================
// Footer
// =============================================================================

$footer-overhang-height: 30px;

// General footer

[role="contentinfo"] {
  background-color: #1d313e;
  background-image: radial-gradient(farthest-corner at 90% 0, #2a5255 0%, #1d313e 100%);
  color: $text-on-teal;

  @include mq-min-width($bp-lap-lg) {
    margin-top: 4rem;

    .pg-landing &,
    .exchange__app & {
      margin-top: 0;
    }
  }

  .container {
    padding-bottom: $grid-spacing-unit;
    padding-top: $grid-spacing-unit;

    @include mq-min-width($bp-desk-plus) {
      padding-bottom: ($grid-spacing-unit * 2);
      padding-top: calc((#{$grid-spacing-unit} * 2) + #{$footer-overhang-height});
    }

    @include mq-min-width($bp-lap-lg) {
      padding-top: calc(#{$grid-spacing-unit} + #{$footer-overhang-height});
      position: relative;

      &::before {
        background-color: #fff;
        content: '';
        display: block;
        height: $footer-overhang-height;
        left: $grid-spacing-unit;
        position: absolute;
        top: 0;
        width: calc(100% - (#{$grid-spacing-unit} * 2));

        .pg-landing & {
          display: none;
        }

        .exchange__app & {
          background-color:  $green-2018-light;
        }
      }
    }
  }
}

.footer-main-nav,
.footer-utility-nav,
.footer-social-links,
.footer-copyright {
  list-style-type: none;
  margin-bottom: 0;
  padding-bottom: $grid-spacing-unit;
  padding-left: 0;
}

.footer-main-nav {
  li + li {
    margin-top: ($grid-spacing-unit / 2);
  }

  a {
    color: #fff;
    font-family: $font-stack-omnes;
    font-weight: 500;
  }
}

.footer-utility-nav {
  li + li {
    margin-top: ($grid-spacing-unit / 4);
  }

  a {
    color: #c7d2dc;
    font-size: (13rem/16);
  }
}

.footer-social-links {
  display: flex;

  li + li {
    margin-left: ($grid-spacing-unit / 1.5);
  }

  svg {
    height: 20px;
    fill: #fff;
    width: auto;

    @include ie-11-only {
      width: 22px;
    }
  }
}

.footer-copyright {
  color: #6b737c;
  font-size: (15rem/16);
  line-height: 2;
}

.footer-donate {
  position: relative;

  &__svg {
    opacity: 0.10;
    position: absolute;
    right: 25px;
    top: 25px;
    transform: scale(2);

    @include mq-min-width($bp-desk-plus) {
      opacity: 0.075;
      right: 40px;
      top: 40px;
      transform: scale(3);
    }
  }

  p {
    font-family: $font-stack-omnes;
    font-size: (22rem/16);
    font-weight: 500;
    padding-right: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}