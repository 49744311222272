.updates {
  padding-bottom: $grid-spacing-unit / 1.5;

  @include mq-min-width($bp-lap-lg) {
    padding-bottom: $grid-spacing-unit;
  }

  @include mq-min-width($bp-desk) {
    padding-bottom: $grid-spacing-unit * 1.5;
  }

  @include mq-min-width($bp-desk-plus) {
    padding-bottom: $grid-spacing-unit * 2;
  }

  &__items {
    margin: 0;
    padding-left: 0;
  }

  &__item {
    border-bottom: 1px solid rgba(85,75,74,.1);
    list-style-type: none;
    padding: ($grid-spacing-unit / 2.5) 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    @include mq-min-width($bp-desk) {
      padding: ($grid-spacing-unit / 1.5) 0;
    }
  }

  &__title {
    line-height: 1.3;
    margin-bottom: 0;

    a {
      font-weight: 500;
    }
  }

  &__brief {
    margin-bottom: 0;
    padding-top: ($grid-spacing-unit / 3);
  }
}
