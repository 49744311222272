// DEFAULTS
// https://developer.mozilla.org/en-US/docs/Web/CSS/animation
// ============================================================================
// animation-name: none
// animation-duration: 0s
// animation-timing-function: ease
// animation-delay: 0s
// animation-iteration-count: 1
// animation-direction: normal
// animation-fill-mode: none
// animation-play-state: running

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Main nav
.pg-home .nav__list > li {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: main-nav-drop-in;
  animation-timing-function: ease-out;

  &:nth-child(2) {
    animation-delay: 0.25s;
  }

  &:nth-child(3) {
    animation-delay: 0.5s;
  }

  &:nth-child(4) {
    animation-delay: 0.75s;
  }
}

@keyframes main-nav-drop-in {
  0%, 50% {opacity: 0; transform: translateY(-10px)}
  100% {opacity: 1; transform: translateY(0)}
}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Main text
.hero-grid__box--main h2 {
  animation-name: hero-grid-main-text;
  animation-duration: 2.25s;
  animation-timing-function: ease-out;
}

@keyframes hero-grid-main-text {
  0%, 44.44% {opacity: 0;}
  100% {opacity: 1;}
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Button
.hero-grid__box--main p {
  animation-name: hero-grid-main-button;
  animation-duration: 3.75s;
  animation-timing-function: ease-out;
}

@keyframes hero-grid-main-button {
  0%, 60% {opacity: 0;}
  100% {opacity: 1;}
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Verbs, borrowed from:
// https://codyhouse.co/demo/animated-headlines/index.html
.hero-grid__verbs .is-visible {
  animation-name: slide-in;
  animation-duration: 0.6s;
}

.hero-grid__verbs .is-hidden {
  animation-name: slide-out;
  animation-duration: 0.6s;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  60% {
    opacity: 0;
    transform: translateY(110%);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Both small boxes
.hero-grid__box--alt-1::after,
.hero-grid__box--alt-2::after {
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-name: fadeSmallBoxes;
}

.hero-grid__box--alt-1::after {
  animation-delay: 0.75s;
}

.hero-grid__box--alt-2::after {
  animation-delay: 1.25s;
}

@keyframes fadeSmallBoxes {
  0%, 44%, 100% { opacity: 1; }
  50%, 94%      { opacity: 0; }
}