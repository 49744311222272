// Hide the header hero.
.pg-annual-celebration {
    .hero {
        min-height: 0;

        @include mq-min-width($bp-lap-lg) {
            min-height: 119px;
        }

        @include mq-min-width($bp-desk-plus) {
            min-height: 85px;
        }

        @include mq-min-width($bp-desk-mid) {
            min-height: 112px;
        }

        * {
            display: none;
            visibility: hidden;
        }
    }
}

.lp-intro {
    @include mq-min-width($bp-lap-lg) {
        transform: translateY(-25%);
    }

    @include mq-min-width($bp-desk-mid) {
        transform: translateY(-40%);
    }
}

// Hide the left column until the viewport is a bit wider.
.annual-celebration-img-col {
    display: none;
    visibility: hidden;

    @include mq-min-width($bp-desk-mid) {
        display: inline-block;
        visibility: visible;
    }

    // Center the form, in the page, until the viewport is a big wider.
    & + .grid__item {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 700px;

        @include mq-min-width($bp-desk-mid) {
            display: inline-block;
            max-width: none;
        }
    }
}