.serp {
  margin-bottom: $grid-spacing-unit;
}


.serp__title {
  color: $color-text;
  font-family: $font-stack-omnes;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  margin-top: $grid-spacing-unit;

  @include mq-min-width($bp-lap) {
    float: left;
    font-size: (58rem/16);
    width: 65%;
  }

  @include mq-min-width($bp-desk) {
    font-size: (78rem/16);
  }
}

// Google Custom Search:
// ----------------------------------------------------------------------------

.gsc-control-cse {
  background-color: transparent !important;
  border: none !important;
  border-color: transparent !important;
  font-family: $font-stack-aktiv !important;
  font-size: inherit !important;
  padding: 0 !important;
}

.gsc-resultsHeader {
  border: none;
  margin-bottom: 0;

  td {
    border: none;
    padding: 0;
  }
}

.gcsc-branding {
  @extend .hidden;
}

.gsc-above-wrapper-area {
  border-bottom: none !important;
  padding: 0 !important;

  table, td {
    border: none;
  }

  .gsc-result-info-container {
    @extend .hidden;
  }

  .gsc-orderby-container {
    padding: 0;
  }
}

.gsc-webResult.gsc-result {
  border: none !important;
  background-color: transparent !important;
}

.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.gs-title, .gs-title * {
  color: $green-2018 !important;
  font-family: "omnes-pro", serif;
  font-size: (32rem/16) !important;
  height: auto !important;
  line-height: 1.25 !important;
  text-decoration: none !important;

  &:hover,
  &:focus {
    text-decoration: underline !important;
  }
}

.gsc-url-top {
  @extend .hidden;
}

.gsc-table-result {
  border: none;
  font-family: $font-stack-aktiv !important;
  margin-top: 12px;

  td {
    border: none;
    font-size: (17.6rem/16) !important;
    padding: 0;
  }
}

.gsc-cursor-box {
  text-align: center !important;
}

.gsc-cursor-page {
  color: $teal !important;
  font-family: sans-serif;
  font-weight: 700;
  padding: 5px 10px 4px;
}

.gsc-cursor-current-page,
.gsc-cursor-page:hover,
.gsc-cursor-page:focus {
  background-color: $green-2018 !important;
  color: #fff !important;
  text-decoration: none !important;
}

.gs-no-results-result {
  font-size: (20rem/16);

  @include mq-min-width($bp-lap) {
    font-size: (26rem/16);
    line-height: 1.2;
  }

  @include mq-min-width($bp-desk) {
    font-size: (34rem/16);
  }

  .gs-snippet {
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}