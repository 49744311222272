// Hide visually and from screen readers: h5bp.com/u

.hidden,
[v-cloak] {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screen readers: h5bp.com/v

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable when
  // navigated to via the keyboard: h5bp.com/p

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Hide visually and from screen readers, but maintain layout.

.invisible {
  visibility: hidden;
}

// Clearfix: contain floats
// For modern browsers
//   1. The space content is one way to avoid an Opera bug when the
//      `contenteditable` attribute is included anywhere else in the document.
//      Otherwise it causes space to appear at the top and bottom of elements
//      that receive the `clearfix` class.
//   2. The use of `table` rather than `block` is only necessary if using
//      `:before` to contain the top-margins of child elements.

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

.btn--strip {
  background-color: transparent;
  border: none;
  padding: 0;
}


.errors {
  background-color: $green-lighter;
  padding: 1.5rem;
}

// Flexbox  source order changing

.flex {
  display: flex;
  flex-direction: column;

  @include mq-min-width($bp-lap-lg) {
    display: inherit;
  }

  &__sidebar {
    order: 2;
  }

  &__content {
    order: 1;
  }
}

// Bumps up text on any module.

.module.text-lg {
  h1, h2 {
    font-size: 2rem;

    @include mq-min-width($bp-desk) {
      font-size: 2.5rem;
    }

    @include mq-min-width($bp-desk-mid) {
      font-size: 3.625rem;
    }
  }

  p, ul, ol {
    font-size: 1.25rem;

    @include mq-min-width($bp-desk-lg) {
      font-size: 1.75rem;
    }
  }
}

// ============================================================================
// Generic vertical padding classes that change at a couple of breakpoints.
// ============================================================================

// Small
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.padding-top-small {
  padding-top: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {padding-top: ($grid-spacing-unit * 1.325);}
  @include mq-min-width($bp-desk-mid) {padding-top: ($grid-spacing-unit * 1.75);}

  // Slightly smaller values on the figure-boxes--home-ctas, specifically.
  &.figure-boxes--home-ctas {
    @include mq-min-width($bp-lap-lg) {padding-top: ($grid-spacing-unit * 1.15);}
    @include mq-min-width($bp-desk-mid) {padding-top: ($grid-spacing-unit * 1.25);}
  }
}
.padding-bottom-small {
  padding-bottom: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {padding-bottom: ($grid-spacing-unit * 1.325);}
  @include mq-min-width($bp-desk-mid) {padding-bottom: ($grid-spacing-unit * 1.75);}
}

// Medium
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.padding-top-medium {
  padding-top: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {padding-top: ($grid-spacing-unit * 1.75);}
  @include mq-min-width($bp-desk-mid) {padding-top: ($grid-spacing-unit * 2.75);}
}
.padding-bottom-medium {
  padding-bottom: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {padding-bottom: ($grid-spacing-unit * 1.75);}
  @include mq-min-width($bp-desk-mid) {padding-bottom: ($grid-spacing-unit * 2.75);}
}

// Large
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.padding-top-large {
  padding-top: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {padding-top: ($grid-spacing-unit * 2.25);}
  @include mq-min-width($bp-desk-mid) {padding-top: ($grid-spacing-unit * 3.75);}
}
.padding-bottom-large {
  padding-bottom: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {padding-bottom: ($grid-spacing-unit * 2.25);}
  @include mq-min-width($bp-desk-mid) {padding-bottom: ($grid-spacing-unit * 3.75);}
}



// ============================================================================
// Generic vertical margin classes that change at a couple of breakpoints.
// ============================================================================

// Small
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.margin-top-small {
  margin-top: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {margin-top: ($grid-spacing-unit * 1.325);}
  @include mq-min-width($bp-desk-mid) {margin-top: ($grid-spacing-unit * 1.75);}
}
.margin-bottom-small {
  margin-bottom: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {margin-bottom: ($grid-spacing-unit * 1.325);}
  @include mq-min-width($bp-desk-mid) {margin-bottom: ($grid-spacing-unit * 1.75);}
}

// Medium
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.margin-top-medium {
  margin-top: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {margin-top: ($grid-spacing-unit * 1.75);}
  @include mq-min-width($bp-desk-mid) {margin-top: ($grid-spacing-unit * 2.75);}
}
.margin-bottom-medium {
  margin-bottom: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {margin-bottom: ($grid-spacing-unit * 1.75);}
  @include mq-min-width($bp-desk-mid) {margin-bottom: ($grid-spacing-unit * 2.75);}
}

// Large
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.margin-top-large {
  margin-top: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {margin-top: ($grid-spacing-unit * 2.25);}
  @include mq-min-width($bp-desk-mid) {margin-top: ($grid-spacing-unit * 3.75);}
}
.margin-bottom-large {
  margin-bottom: $grid-spacing-unit;
  @include mq-min-width($bp-lap-lg) {margin-bottom: ($grid-spacing-unit * 2.25);}
  @include mq-min-width($bp-desk-mid) {margin-bottom: ($grid-spacing-unit * 3.75);}
}