// =============================================================================
// Page Header
// =============================================================================

.page-header {
  h1 {
    font-size: 2.5rem;
  }
  p {
    font-size: 1.15rem;
    margin-bottom: 0;
  }

  @include mq-min-width($bp-lap-lg) {
    h1 {
      font-size: 4rem;
    }
    p {
      font-size: 1.35rem;
      margin-bottom: 0;
    }
  }
}

// specificity issues on old grid system
.module .page-header .grid {
  @include mq-min-width($bp-lap-lg) {
    padding-top: $grid-spacing-unit / 2;
  }
}


// =============================================================================
// Page Header: Search (used in Exchange)
// =============================================================================

.page-header__search {
  @include mq-min-width($bp-lap) {
    margin-top: -8px !important;
  }

  @include mq-min-width($bp-lap-lg) {
    margin-top: 5px !important;
  }

  .page-header__search-submit {
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    top: -2px;
  }
}

.page-header__search-input {
  border: 1px solid #ccc;
  padding: 17px;
  width: calc(100% - 148px);
}


