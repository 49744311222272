// =============================================================================
// Buttons
// =============================================================================

/*------------------------------------*\
    $BASE
\*------------------------------------*/
/**
 * Base button styles.
 *
 * 1. Allow us to better style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Stop buttons wrapping and looking broken.
 * 4. Make buttons inherit font styles.
 * 5. Force all elements using beautons to appear clickable.
 * 6. Normalise box model styles.
 * 7. If the button’s text is 1em, and the button is (3 * font-size) tall, then
 *    there is 1em of space above and below that text. We therefore apply 1em
 *    of space to the left and right, as padding, to keep consistent spacing.
 * 8. Basic cosmetics for default buttons. Change or override at will.
 * 9. Don’t allow buttons to have underlines; it kinda ruins the illusion.
 */

.btn {
  background-color: transparent;
  border: 2px solid $green-2018;
  color: $green-2018;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: 700;
  line-height: $grid-spacing-unit * 1.5;
  margin: 0;
  padding-bottom: 0;
  padding-left: $grid-spacing-unit;
  padding-right: $grid-spacing-unit;
  padding-top: 0;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;

  &:hover,
  &:focus {
    background-color: transparentize($color: $green-2018, $amount: .85);
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &[disabled] {
    background-color: $mid-grey;
    cursor: not-allowed;
  }
}

// Modifier: white border and text color.
.btn--white {
  border-color: #fff;
  color: #fff;

  &:hover,
  &:focus {
    background-color: transparentize($color: #fff, $amount: .85);
  }
}

// Modifier: smaller button.
.btn--small {
  line-height: 1.5;
  padding-right: .5rem;
  padding-left: .5rem;
}

// Modifier: larger button.
.btn--large {
  line-height: 3;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

// Modifier: huge button.
.btn--huge {
  padding-right: 2rem;
  padding-left: 2rem;
  line-height: 5;
}

// Modifier: full-width button.
.btn--full {
  width: 100%;
  text-align: center;
}

// Modifier: button containing play icon svg.
.btn--vid,
.btn--video {
  svg {
    margin-right: (10rem/16);
    position: relative;
    top: -1px;

    path {
      fill: $green-2018;
    }
  }

  &.btn--white {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

// Modifier: dark background.
.btn--darkbg {
  background-color: $dark-grey;
  color: $light-grey;
}

// Modifier: reverse default colors.
.btn--rev {
  background-color: #fff;
  color: $brown !important;

  &:hover,
  &:focus {
    background-color: darken( $green-2018, 5%);
    color: white !important;
  }
}

// Modifier: reverse default colors, alternate version.
.btn--rev-alt {
  background-color: #fff;
  color: $green-2018 !important;

  &:hover,
  &:focus {
    background-color: $green-2018;
    color: white !important;
  }
}

// Modifier: positive colors.
.btn--positive {
  background-color: #4A993E;
  color: #fff;
}

// Modifier: negative colors.
.btn--negative {
  background-color: #b33630;
  color: #fff;
}

// Modifier: red/pink background.
.btn--red {
  background-color: $red-2018 !important;
  border: none;
  border-radius: $border-radius;
  color: #fff;
  font-size: (13rem/16);
  line-height: $grid-spacing-unit;
  padding-left: ($grid-spacing-unit / 1.5);
  padding-right: ($grid-spacing-unit / 1.5);

  &:hover,
  &:focus {
    background-color: darken($red-2018, 10%) !important;
    color: #fff !important;
  }
}

// Modifier: make the button look like text.
.btn--strip {
  background-color: transparent;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  text-align: left;
}
