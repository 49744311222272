$cc-layout-swap: 1024px;

$cc-col-1-width-at-1024: 25%;
$cc-col-2-width-at-1024: 18%;
$cc-col-3-width-at-1024: 23%;
$cc-col-4-width-at-1024: 34%;

$cc-col-1-width-at-1400: 38%;
$cc-col-2-width-at-1400: 15%;
$cc-col-3-width-at-1400: 23%;
$cc-col-4-width-at-1400: 24%;


// Wrapper
// ----------------------------------------------------------------------------

.cc-wrapper {
    background-color: #eeeae6;
    margin-left: auto;
    margin-right: auto;
    max-width: 1820px;
    min-height: 100vh;
}


// Header
// ----------------------------------------------------------------------------

.cc-header {
    align-items: center;
    background-color: #222;
    background-image: url('/lib/img/cc-header-small.jpg');
    background-position: right top;
    background-size: cover;
    display: flex;
    justify-content: center;
    min-height: 250px;

    @include mq-min-width($bp-lap) {
        background-image: url('/lib/img/cc-header-medium.jpg');
        min-height: 300px;
    }

    @include mq-min-width($cc-layout-swap) {
        min-height: 470px;
    }

    @include mq-min-width(1201px) {
        background-image: url('/lib/img/cc-header-large.jpg');
    }

    @include mq-min-width($bp-desk-lg) {
        min-height: 600px;
    }

    &__content {
        @include mq-min-width($cc-layout-swap) {
            transform: translateY(-45px);
        }
    }

    &__title,
    &__tagline {
        color: #fff;
    }

    &__title {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        @include mq-min-width($bp-lap) {
            align-items: flex-end;
            flex-direction: row;
            margin-bottom: 12px;
        }

        span {
            font-size: (40rem/16);
            font-weight: 700;
            margin-bottom: 8px;

            @include mq-min-width($bp-lap) {
                font-size: (55rem/16);
                margin-bottom: 0;
                margin-right: 24px;
            }

            @include mq-min-width(768px) {
                font-size: (70rem/16);
            }
        }

        sup {
            font-size: 50%;
            font-weight: normal;
            top: -0.75em;
        }

        svg {
            height: 40px;
            width: 133px;

            @include mq-min-width($bp-lap) {
                transform: translateY(-3px);
            }

            @include mq-min-width(768px) {
                height: 50px;
                transform: translateY(-5px);
                width: 166px;
            }
        }
    }

    &__tagline {
        letter-spacing: 0.5em;
        margin-bottom: 0;
        text-shadow: 0 3px 46px #000;
        text-transform: uppercase;

        svg {
            display: none;
            visibility: hidden;

            @include mq-min-width($bp-lap) {
                display: inline;
                height: 30px;
                visibility: visible;
                width: 64px;
            }
        }
    }
}


// Left & Right Column Layout
// ----------------------------------------------------------------------------

.cc-wrapper main {
    @include mq-min-width($cc-layout-swap) {
        display: flex;
        margin-right: auto;
        margin-left: auto;
        max-width: 1600px;
        transform: translateY(-90px);
    }
}

.cc-left-col,
.cc-right-col {
    padding: 16px;

    @include mq-min-width($bp-desk-lg) {
        padding: 32px;
    }
}

.cc-left-col {
    background-color: $green-light;

    @include mq-min-width($cc-layout-swap) {
        margin-right: 16px;
    }

    @include mq-min-width(1150px) {
        flex: none;
        width: 408px;
    }
}

.cc-right-col {
    background-color: #fff;
    position: relative;

    @include mq-min-width($cc-layout-swap) {
        flex: auto;
        margin-left: 16px;
    }
}


// Search (in left column)
// ----------------------------------------------------------------------------

.cc-search {
    &__title {
        h1 {
            border-bottom: 1px solid $teal;
            font-weight: 700;
            padding-bottom: 0.333em;
        }
    }

    &__form {
        .input--w-dropdown {
            align-items: flex-start;
            display: flex;
            flex-direction: column;

            @include mq-min-width(375px) {
                flex-direction: row;
                justify-content: space-between;
            }

            @include mq-min-width(550px) {
                flex-direction: column;
                justify-content: flex-start;
            }

            @include mq-min-width(1150px) {
                flex-direction: row;
                justify-content: space-between;
            }

            label {
                margin-bottom: 8px;

                @include mq-min-width(375px) {
                    margin-bottom: 0;
                }

                @include mq-min-width(550px) {
                    margin-bottom: 8px;
                }

                @include mq-min-width(1150px) {
                    margin-bottom: 0;
                }
            }

            select {
                min-width: 45px;
            }
        }

        label,
        .faux-label {
            font-size: 1rem;
            letter-spacing: 0;
            text-transform: none;
        }

        [type='text'] {
            padding: 12px;
            width: 100%;
        }

        .exclude-results {
            border-top: 1px solid transparentize($color: $brown-dark, $amount: 0.8);
            display: flex;
            padding-top: 16px;

            input {
                flex: none;
            }

            label {
                font-size: (14rem/16);
                margin-left: 14px;
                position: relative;
                top: -0.5px;
            }
        }

        [type='submit'] {
            @include mq-min-width($cc-layout-swap) {
                width: 100%;
            }
        }
    }

    &__industries {
        list-style-type: none;
        margin-top: 16px;
        padding: 0;

        li + li {
            margin-top: 0.25em;
        }
    }
}


// Small 'help' links at the top-right of the right column
// ----------------------------------------------------------------------------

.cc-small-help {
    margin-bottom: 32px;

    @include mq-min-width($cc-layout-swap) {
        float: right;
        margin-left: 64px;
    }

    button {
        color: $brown-dark;
        display: inline-block;
        position: relative;
        text-decoration: underline;

        &:not(:first-child) {
            padding-left: 0.75em;

            &:before {
                background-color: $brown-dark;
                content: '';
                height: 65%;
                left: 3px;
                position: absolute;
                top: 3px;
                width: 1px;
            }
        }

        &:hover,
        &:focus {
            color: #000;
        }

        &:focus {
            outline: none;
        }
    }
}


// Welcome (in right column, shown before search is submitted)
// ----------------------------------------------------------------------------

.cc-welcome {
    h1 {
        border-bottom: 1px solid transparentize($brown-dark, 0.5);
        color: $brown-dark;
        padding-bottom: 11px;
    }

    &__description {
        font-size: (22rem/16);
        max-width: 900px;

        &-search::before {
            content: 'above ';

            @include mq-min-width($cc-layout-swap) {
                content: 'on the left ';
            }
        }
    }

    &__cta {
        p {
            font-size: (20rem/16);
            margin-bottom: 12px;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
        }

        li {
            &:not(:first-child) {
                margin-top: 8px;
            }

            button {
                border-radius: 8px;
                display: block;
                padding: 16px;
                text-decoration: none;
                width: 100%;

                @include mq-min-width($cc-layout-swap) {
                    padding: 24px;
                }

                &:focus {
                    outline: none;
                }

                span {
                    &:first-of-type {
                        color: $teal;
                        font-size: (26rem/16);
                    }

                    &:last-of-type {
                        display: none;
                        visibility: hidden;

                        @include mq-min-width($bp-lap) {
                            color: $brown-dark;
                            display: inline;
                            font-size: (20rem/16);
                            padding-left: 24px;
                            position: relative;
                            visibility: visible;

                            &:before {
                                background-color: $teal;
                                content: '';
                                height: 24px;
                                left: 10px;
                                position: absolute;
                                top: -4px;
                                width: 1px;
                            }
                        }
                    }
                }
            }

            &:nth-child(1) {
                button {
                    background-color: #e6ded8;

                    &:hover,
                    &:focus {
                        background-color: lighten(#e6ded8, 2.5%);
                    }
                }
            }

            &:nth-child(2) {
                button {
                    background-color: $green-light;

                    &:hover,
                    &:focus {
                        background-color: lighten($green-light, 2.5%);
                    }
                }
            }

            &:nth-child(3) {
                button {
                    background-color: $green;

                    &:hover,
                    &:focus {
                        background-color: lighten($green-light, 2.5%);
                    }
                }
            }
        }
    }
}


// Results (in right column, shown after search is submitted)
// ----------------------------------------------------------------------------

.cc-results {
    h1 {
        border-bottom: 1px solid transparentize($brown-dark, 0.5);
        color: $brown-dark;
        font-weight: 700;
        padding-bottom: 10px;
    }

    &__warning {
        background-color: transparentize($teal, 0.85);
        border: 1px solid $teal;
        color: $brown-dark;
        padding: 16px;
    }

    &__intro {
        font-size: (24rem/16);
        margin-bottom: 8px;
        max-width: 900px;

        strong {
            color: $teal;
        }
    }

    &__legend {
        font-size: (20rem/16);
        max-width: 900px;
    }

    &__tabs {
        display: flex;
        flex-wrap: wrap;
        transform: translateX(-16px);
        width: calc(100% + 32px);

        @include mq-min-width($cc-layout-swap) {
            border-bottom: 1px solid transparentize($brown-dark, .8);
            transform: none;
            width: auto;
        }

        label {
            width: 50%;

            @include mq-min-width($cc-layout-swap) {
                width: auto;

                &:not(:first-of-type) {
                    margin-left: 16px;
                }
            }

            span {
                border-bottom: 1px solid transparentize($brown-dark, .8);
                display: block;
                font-size: (14rem/16);
                padding: 16px;
                position: relative;
                text-align: left;

                @include mq-min-width($cc-layout-swap) {
                    border-bottom: none;
                    font-size: 1rem;
                    padding-left: 0;
                    padding-right: 0;
                }

                strong {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    @include mq-min-width(768px) {
                        display: inline;
                        overflow: clip;
                        text-overflow: clip;
                        white-space: normal;
                    }
                }
            }

            :hover + span,
            :focus + span {
                cursor: pointer;

                &:after {
                    background-color: lighten($teal, 35%);
                    content: '';
                    height: 5px;
                    left: 0;
                    position: absolute;
                    top: calc(100% - 2px);
                    width: 100%;
                }
            }

            :checked + span {
                &:before {
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid $teal;
                    content: '';
                    left: 50%;
                    position: absolute;
                    top: 100%;
                    transform: translateX(-50%);
                }
                &:after {
                    background-color: $teal;
                    content: '';
                    height: 5px;
                    left: 0;
                    position: absolute;
                    top: calc(100% - 2px);
                    width: 100%;
                }
            }
        }

        .bright-horizon {
            padding: 24px 16px;
            margin-bottom: 16px;
            width: 100%;

            @include mq-min-width($cc-layout-swap) {
                margin-left: auto;
                margin-bottom: 0;
                padding-bottom: 0;
                padding-top: 16px;
                width: auto;
            }
        }
    }

    &__list-header {
        display: none;
        visibility: hidden;

        @include mq-min-width($cc-layout-swap) {
            background-color: $green-light;
            display: flex;
            margin-top: 16px;
            visibility: visible;
        }

        > div {
            font-size: (14rem/16);
            padding: 8px 12px;

            &:nth-child(1) {
                width: $cc-col-1-width-at-1024;

                @include mq-min-width($bp-desk-lg) {
                    width: $cc-col-1-width-at-1400;
                }
            }

            &:nth-child(2) {
                width: $cc-col-2-width-at-1024;

                @include mq-min-width($bp-desk-lg) {
                    width: $cc-col-2-width-at-1400;
                }
            }

            &:nth-child(3) {
                width: ($cc-col-3-width-at-1024 + $cc-col-4-width-at-1024);

                @include mq-min-width($bp-desk-lg) {
                    width: ($cc-col-3-width-at-1400 + $cc-col-4-width-at-1400);
                }
            }
        }

        button {
            &:focus {
                outline: none;
            }

            &.sort-ascending,
            &.sort-descending {
                color: $teal;

                &::after {
                    font-size: 11px;
                    padding-left: 0.75em;
                }
            }

            &.sort-ascending {
                &::after {
                    content: '▼';
                }
            }

            &.sort-descending {
                &::after {
                    content: '▲';
                }
            }
        }
    }

    &__list {
        margin-bottom: 0;
        padding-left: 0;

        > li {
            list-style-type: none;
            padding-left: 0;
        }
    }

    &__job {

        & + &  {
            margin-top: 40px;

            @include mq-min-width($cc-layout-swap) {
                border-top: 1px solid #e5ddd9;
                margin-top: 0;
            }
        }

        // Default (MORE INFO *is not* open)
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        display: flex;
        flex-wrap: wrap;

        @include mq-min-width($cc-layout-swap) {
            align-items: center;
        }

        &-title {
            width: 100%;

            @include mq-min-width($cc-layout-swap) {
                font-size: (14rem/16);
                padding: 16px 12px;
                width: $cc-col-1-width-at-1024;
            }

            @include mq-min-width($bp-desk-lg) {
                font-size: 1rem;
                width: $cc-col-1-width-at-1400;
            }

            .bright-horizon-icon {
                transform: translateY(-2px);
            }
        }

        &-salary,
        &-edu {
            margin-top: 16px;

            @include mq-min-width($cc-layout-swap) {
                font-size: (14rem/16);
                padding: 16px 12px;
                margin-top: 0;
            }

            @include mq-min-width($bp-desk-lg) {
                font-size: 1rem;
            }
        }

        &-salary {
            width: 40%;

            @include mq-min-width($cc-layout-swap) {
                width: $cc-col-2-width-at-1024;
            }

            @include mq-min-width($bp-desk-lg) {
                width: $cc-col-2-width-at-1400;
            }
        }

        &-edu {
            width: 60%;

            @include mq-min-width($cc-layout-swap) {
                width: $cc-col-3-width-at-1024;
            }

            @include mq-min-width($bp-desk-lg) {
                width: $cc-col-3-width-at-1400;
            }
        }

        &-label {
            display: block;

            @include mq-min-width($cc-layout-swap) {
                display: none;
                visibility: hidden;
            }
        }

        &-footer {
            align-items: center;
            display: flex;
            margin-top: 24px;
            padding: 12px 0;
            position: relative;
            width: 100%;

            @include mq-min-width($cc-layout-swap) {
                justify-content: flex-end;
                margin-top: 0;
                padding: 16px 12px;
                transform: none;
                width: $cc-col-4-width-at-1024;
            }

            @include mq-min-width($bp-desk-lg) {
                width: $cc-col-4-width-at-1400;
            }

            &,
            &::before,
            &::after {
                background-color: #f5f5f5;
                border-bottom: 1px solid transparentize($brown-dark, .8);

                @include mq-min-width($cc-layout-swap) {
                    background-color: transparent;
                    border-bottom: none;
                }
            }

            &::before,
            &::after {
                content: '';
                height: calc(100% + 1px);
                position: absolute;
                top: 0;
                width: 16px;

                @include mq-min-width($cc-layout-swap) {
                    content: none;
                }
            }

            &::before {
                left: -16px;
            }

            &::after {
                right: -16px;
            }
        }

        &-view {
            margin-right: 24px;

            @include mq-min-width($cc-layout-swap) {
                margin-left: 16px;
                margin-right: 0;
                order: 2;
            }

            .btn {
                line-height: 3;
            }
        }

        &-toggle {
            @include mq-min-width($cc-layout-swap) {
                order: 1;
            }

            button {
                color: $color-link;
                font-weight: bold;
                font-size: (13rem/16);
                text-transform: uppercase;

                &::after {
                    content: '▼';
                    font-size: 9px;
                    padding-left: 0.5em;
                    position: relative;
                    top: -1px;
                }

                &:hover,
                &:focus {
                    color: $teal;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        &-info {
            display: none;
            visibility: hidden;
        }

        // MORE INFO *is* open
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        &.is-open {
            .cc-results__job-footer {
                &,
                &::before,
                &::after {
                    border-bottom-color: transparent;
                }
            }

            .cc-results__job-toggle {
                button {
                    color: $teal;

                    &::after {
                        content: '▲';
                    }
                }
            }

            .cc-results__job-info {
                display: block;
                padding-bottom: 12px;
                position: relative;
                visibility: visible;
                width: 100%;

                @include mq-min-width($cc-layout-swap) {
                    border-radius: 8px;
                    margin: 0 0 12px 12px;
                    padding: 12px;
                    width: calc(100% - 24px);
                }

                &,
                &::before,
                &::after {
                    background-color: #f5f5f5;
                    border-bottom: 1px solid transparentize($brown-dark, .8);

                    @include mq-min-width($cc-layout-swap) {
                        border-bottom: none;
                    }
                }

                &::before,
                &::after {
                    content: '';
                    height: calc(100% + 1px);
                    position: absolute;
                    top: 0;
                    width: 16px;

                    @include mq-min-width($cc-layout-swap) {
                        content: none;
                    }
                }

                &::before {
                    left: -16px;
                }

                &::after {
                    right: -16px;
                }

                ul {
                    margin-bottom: 0;

                    @include mq-min-width($cc-layout-swap) {
                        list-style-type: none;
                        padding-left: 0;
                    }
                }

                li {
                    @include mq-min-width($cc-layout-swap) {
                        display: inline;

                        &:not(:first-child) {
                            margin-left: 16px;
                            padding-left: 16px;
                            position: relative;

                            &::before {
                                background-color: $color-text;
                                content: '';
                                height: 1em;
                                left: 0;
                                position: absolute;
                                top: 0;
                                width: 1px;
                            }
                        }

                    }
                }
            }
        }
    }

    .pagination {
        text-align: center;
    }
}


// Close button(s) in the FAQs, User Guide, and Additional Resources views
// ----------------------------------------------------------------------------

.cc-faqs,
.cc-user-guide,
.cc-additional-resources {
    h1 {
        font-weight: 700;

        .btn--small {
            font-family: $font-stack-aktiv;
            font-weight: 400;
            margin-left: 16px;

            span {
                display: inline-block;
                line-height: 0;
                transform: scale(1.4) translate(-1px, -0.5px);
            }
        }
    }
}


// FAQs (in right column, shown when triggered)
// ----------------------------------------------------------------------------

.cc-faqs {
    h1 {
        border-bottom: 1px solid transparentize($brown-dark, 0.5);
        color: $brown-dark;
        padding-bottom: 11px;
    }

    &__group {
        margin-top: 40px;

        @include mq-min-width($cc-layout-swap) {
            margin-top: 60px;
        }

        > h2 {
            color: $brown-dark;
            font-size: 1rem;
            font-weight: bold;
            letter-spacing: 0.06em;
            text-transform: uppercase;
        }
    }

    &__qa {
        border-bottom: 1px solid #f0f5eb;
        padding: 16px 8px;

        @include mq-min-width($cc-layout-swap) {
            padding: 32px 16px;
        }

        &:first-of-type {
            border-top: 1px solid #f0f5eb;
        }

        &.is-open {
            background-color: #f0f5eb;
        }
    }

    &__question {
        align-items: flex-start;
        color: $teal;
        display: flex;
        font-size: (22rem/16);
        line-height: 1.2;
        margin-bottom: 0;

        &:hover {
            color: $brown-dark;
            cursor: pointer;
        }

        span {
            &:first-of-type {
                flex: none;
                font-size: (30rem/16);
                line-height: 0.8;
                text-align: center;
                transform: rotate(45deg);
                transition-property: transform;
                transition-duration: 300ms;
                width: 24px;

                .is-open & {
                    transform: rotate(0);
                }
            }

            &:last-of-type {
                margin-left: 8px;
            }
        }
    }

    &__answer {
        display: none;
        visibility: hidden;

        .is-open & {
            color: $brown-dark;
            display: block;
            padding: 1em 0 0 32px;
            visibility: visible;
        }

        > :last-child {
            margin-bottom: 0;
        }

        .btn--small {
            transform: translateY(-2px);
        }

        a {
            color: inherit;

            &:hover,
            &:focus {
                color: $teal;
            }
        }
    }

    &__next {
        margin-top: 40px;

        @include mq-min-width($cc-layout-swap) {
            margin-top: 96px;
        }

        figure {
            border: 1px solid #dbdbdb;
            border-radius: $border-radius;
            display: none;
            padding: 8px;

            @include mq-min-width($cc-layout-swap) {
                display: block;
                float: left;
            }
        }

        > :not(figure) {
            @include mq-min-width($cc-layout-swap) {
                margin-left: 428px;
            }
        }

        h2 {
            font-size: (32rem/16);
            margin-top: 0;
        }
    }
}


// User Guide (in right column, shown when triggered)
// ----------------------------------------------------------------------------

.cc-user-guide {
    color: $brown-dark;

    h1 {
        border-bottom: 1px solid transparentize($brown-dark, 0.5);
        color: $brown-dark;
        padding-bottom: 11px;
    }

    &__intro {
        color: #736a69;
        font-size: (22rem/16);
        margin-top: 32px;

        @include mq-min-width($cc-layout-swap) {
            margin-top: 48px;
        }
    }
}


// Additional Resources (in right column, shown when triggered)
// ----------------------------------------------------------------------------

.cc-additional-resources {
    h1 {
        border-bottom: 1px solid transparentize($brown-dark, 0.5);
        color: $brown-dark;
        padding-bottom: 11px;
    }
}


// Footer
// ----------------------------------------------------------------------------

.cc-footer {
    padding: 32px 16px;
    text-align: center;

    @include mq-min-width($cc-layout-swap) {
        transform: translateY(-90px);
    }

    @include mq-min-width($bp-desk-lg) {
        padding: 32px;
    }

    &__contact {
        strong {
            color: $teal;
            display: block;
            font-family: $font-stack-omnes;
            font-size: (22rem/16);
            line-height: 1.2;
            margin-bottom: 4px;
            text-transform: uppercase;

            @include mq-min-width($bp-desk) {
                display: inline-block;
                line-height: inherit;
                margin-bottom: 0;
                margin-right: 16px;
                padding-right: 16px;
                position: relative;

                &::after {
                    background-color: $teal;
                    content: '';
                    height: 1em;
                    position: absolute;
                    right: -2px;
                    top: 5px;
                    width: 2px;
                }
            }
        }

        span,
        a {
            color: $brown-dark;
            font-size: (18rem/16);
        }

        span {
            @include mq-min-width($bp-desk) {
                display: inline-block;
                transform: translateY(-2px);
            }

            svg {
                height: 17px;
                margin-right: 8px;
                transform: translateY(-1px);
                width: 17px;
            }
        }
    }
}