.faqs {
    .js & {
        &__item {
            &:not(:last-child) {
                margin-bottom: 24px;
            }

            .faqs__question {
                color: $green-2018;
                margin-bottom: 0;
                padding-right: 40px;
                position: relative;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                &::after {
                    background-image: url('/lib/svg/arrow-down.svg');
                    background-repeat: no-repeat;
                    content: '';
                    display: block;
                    height: 12px;
                    position: absolute;
                    right: 0;
                    top: 15px;
                    transform: rotate(0deg);
                    transition-duration: 300ms;
                    transition-property: transform;
                    width: 22px;
                }
            }

            &.is-open {
                .faqs__question {
                    &::after {
                        transform: rotate(180deg);
                    }
                }

                .faqs__answer {
                    margin-bottom: 40px;
                    margin-top: 16px;
                }
            }
        }
    }
}