// =============================================================================
// Data points
// =============================================================================

// Overides to .module

.module .data {
  h2 {
    font-size: .6875rem;
  }
}

// Overides to .ertiary

.tertiary {
  .data {
    background-image: none;

    .numero {
      font-size: 2rem;
    }
    .ruler {
      opacity: .5;
    }
  }
}

// Container + general rules

.data {
  background: linear-gradient(to top, rgba(255,255,255,0) 50%, rgba(0,0,0,0.05) 100%);
  overflow: hidden; // Prevent horizontal scroll
  position: relative;

  @include mq-min-width($bp-lap-lg) {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(0,0,0,0.05) 100%);
  }

  h2 {
    color: #c3bcb7;
    font-size: .6875rem;
    font-family: $font-stack-aktiv;
    font-weight: 700;
    text-transform: uppercase;
  }
}

// Another wrapper to provide grid silulation AND layering on top of ruler lines

.data__points {
  font-size: 0; // Inline block fix
  margin-left: $grid-spacing-unit / 2; // Simulate grid item spacing
  margin-top: $grid-spacing-unit;
  padding-right: $grid-spacing-unit;
  width: 100%;

  @include mq-min-width($bp-lap-lg) {
    margin-left: $grid-spacing-unit; // Simulate grid item spacing
  }

  @include mq-min-width($bp-desk-mid) {
    padding-right: 0;
    position: absolute;
    z-index: 10;
  }
}

// Individual data point "Stability, "Mobility" etc

.data__item {
  display: inline-block; // Inline block fix
  font-size: 1rem;
  vertical-align: top;
  width: 100%;

  @include mq-min-width($bp-lap-lg) {
    width: 50%;
  }

  @include mq-min-width($bp-desk-mid) {
    margin-right: 9%; // Aligns to alt tall ruler points.
    width: 18%;
  }

  p {
    padding: 0 1.375rem;

    @include mq-min-width($bp-lap) {
      font-size: 1.2rem;
    }

    @include mq-min-width($bp-desk-mid) {
      font-size: 1rem;
    }
  }

  .numero {
    display: block;
    font-family: $font-stack-aktiv;
    font-size: 4.375rem;
    font-weight: 700;
    line-height: .75;
    margin-bottom: 1rem;
    position: relative;

    // Line set to left of the large number

    &:after {
      width: 5px;
      height: 100%;
      background-color: $green-2018;
      position: absolute;
      left: -1.375rem;
      content: "";
      top: 0;
    }
  }

  // Color system for numbers

  &:nth-child(1) {
    .numero {
      color: #dde4c8;
    }
  }

  &:nth-child(2) {
    .numero {
      color: #d5cfbe;
    }
  }

  &:nth-child(3) {
    .numero {
      color: #bcd0b7;
    }
  }

  &:nth-child(4) {
    .numero {
      color: #d1d3cb;
    }
  }

  &:last-child {
    margin-right: 0;
    padding-bottom: 4rem;

    @include mq-min-width($bp-desk-mid) {
      padding-bottom: 0;
    }

    p {
      @include mq-min-width($bp-desk-mid) {
        padding-right: $grid-spacing-unit;
      }
    }
  }
}


// The ruler / gugae lines themselves.

.ruler {
  position: absolute;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  margin-left: $grid-spacing-unit;
  margin-right: $grid-spacing-unit;
  min-height: 176px; // Controls the min height for
  bottom: 0;


  @include mq-min-width($bp-desk-mid) {
    min-height: 276px;
    position: relative;
    bottom: auto;
  }

  .cm {
    padding: 0 4.5%;
    height: 210px;
    border-left: 1px dashed #d9dadb;

    .mm:first-child {
      border: none;
    }

    .mm:last-child {
      border: none;
    }
  }

  .mm {
    height: 80px;
    width: 11.1111111111%;
    bottom: 0;
    border-left: 1px solid #ededee;
  }

  .cm,
  .mm {
    position: absolute;
    bottom: 0;
    z-index: 5;
  }


  @for $i from 0 through 10 {
   .cm:nth-of-type(#{$i+1}) {
      left: #{$i*9%};
    }
  }

  @for $j from 1 through 10 {
    .mm:nth-of-type(#{$j}) {
      left: #{$j*10%};
    }
  }
}