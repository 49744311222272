// =============================================================================
// Min-width.
// =============================================================================

@mixin mq-min-width($screen-width) {
  @media only screen and (min-width: $screen-width) {
    @content;
  }
}


// =============================================================================
// Max-width.
// =============================================================================

@mixin mq-max-width($screen-width) {
  @media only screen and (max-width: $screen-width) {
    @content;
  }
}


// =============================================================================
// Between two widths.
// =============================================================================

@mixin mq-between-width($min-width, $max-width) {
  @media only screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}


// =============================================================================
// Min-height.
// =============================================================================

@mixin mq-min-height($screen-height) {
  @media only screen and (min-height: $screen-height) {
    @content;
  }
}


// =============================================================================
// Max-height.
// =============================================================================

@mixin mq-max-height($screen-height) {
  @media only screen and (max-height: $screen-height) {
    @content;
  }
}


// =============================================================================
// Between two heights.
// =============================================================================

@mixin mq-between-height($min-height, $max-height) {
  @media only screen and (min-height: $min-height) and (max-height: $max-height) {
    @content;
  }
}


// =============================================================================
// DPR: 2x.
// =============================================================================

@mixin mq-2x {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
         only screen and (min-resolution: 192dpi) {
    @content;
  }
}


// =============================================================================
// DPR: 3x.
// =============================================================================

@mixin mq-3x {
  @media only screen and (-webkit-min-device-pixel-ratio: 3),
         only screen and (min-resolution: 288dpi) {
    @content;
  }
}


// =============================================================================
// DPR: 4x.
// =============================================================================

@mixin mq-4x {
  @media only screen and (-webkit-min-device-pixel-ratio: 4),
         only screen and (min-resolution: 384dpi) {
    @content;
  }
}
