$text-color: #fff;

.feedback {
  background-color: $green-2018;
  color: $text-color;
  margin-bottom: $grid-spacing-unit !important;
  margin-top: $grid-spacing-unit;
  padding: 20px 15px;

  @include mq-min-width($bp-desk-lg) {
    padding: ($grid-spacing-unit / 1.63);
  }

  &__header {
    display: table;
    table-layout: fixed;
  }

  &__avatar,
  &__greeting {
    display: table-cell;
  }

  &__avatar {
    width: 68px;
  }

  &__greeting {
    color: $text-color;
    font-size: (20rem/16);
    padding-left: 15px;
    vertical-align: middle;

    @include mq-min-width($bp-desk-lg) {
      font-size: (26rem/16);
    }
  }

  &__body {
    line-height: 1.3;
    margin-top: ($grid-spacing-unit / 2);

    @include mq-min-width($bp-palm) {
      font-size: (18rem/16);
    }

    @include mq-min-width($bp-desk-lg) {
      font-size: (20rem/16);
    }
  }

  &__input {
    border: 1px solid #d5d5cd;
    color: $color-text;
    display: block;
    height: 125px;
    margin-bottom: ($grid-spacing-unit / 2);
    padding: 15px;
    width: 100%;
  }

  &__submit {
    background-color: #3f8c7b !important;

    &:hover,
    &:focus {
      background-color: $brown !important;
    }

    &[disabled] {
      background-color: $mid-grey !important;

      &:hover,
      &:focus {
        background-color: $mid-grey !important;
      }
    }
  }

  &__checkmark {
    height: 23px;
    margin-left: 10px;
    width: 32px;

    path {
      fill: $green-light;
    }
  }
}
