// =============================================================================
// Calls to action (CTA)
// =============================================================================

.cta {
  margin-top: $element-spacing-unit;
  background-color: $green-2018-light;
  background-image: url(/lib/svg/inline/little-bridges.svg);
  background-repeat: no-repeat;
  background-position: bottom right -50px;
  font-size: 1.125rem;
  padding: $element-spacing-unit / 2;

  @include mq-min-width($bp-lap-lg) {
    padding: $element-spacing-unit / 2 $element-spacing-unit / 3;
    font-size: 1rem;
    margin-left: -1.5rem;
  }

  @include mq-min-width($bp-desk) {
    padding: $grid-spacing-unit $element-spacing-unit / 2;
  }

  @include mq-min-width($bp-desk-plus) {
    margin-left: 0;
  }

  @include mq-min-width($bp-desk-lg) {
    padding: $grid-spacing-unit $element-spacing-unit;
    font-size: 1.125rem;
  }

  img {
    background-color: #fff;
    padding: 0;
    border: none;
    border-radius: 0;
  }

  .label {
    color: $green-2018;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    border-left: 3px solid $green-2018;
    line-height: 1;
    padding-left: 1rem;
    margin-bottom: $element-spacing-unit / 2;

    @include mq-min-width($bp-desk-mid) {
      font-size: 1.25rem;
      margin-bottom: $element-spacing-unit;
    }
  }

  .btn, button {
    font-size: 14px;
  }

  h1 {
    font-size: 1.85rem;

    @include mq-min-width($bp-lap-lg) {
      font-size: 1rem;
      margin-bottom: .5rem;
    }

    @include mq-min-width($bp-desk) {
      font-size: 1.4rem;
    }

    @include mq-min-width($bp-desk-mid) {
      font-size: 1.8rem;
    }
  }
}