// =============================================================================
// Home
// =============================================================================

.pg-home {
  .module {
    border-bottom: none;
  }
}

.home-hero {
  @include mq-min-width($content-max-width) {
    margin: 0 2rem;
    position: relative;
    z-index: 1;
  }
}


.home-alert {
  position: relative;
  z-index: 100000;
  width: 100%;
  background-color: #58ab97;
  padding: 40px;
  color: #fff;
  box-shadow: 3px 3px 30px 6px rgba(0,0,0,.5);
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;

  a {
    color: #fff;
  }
}