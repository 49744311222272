.results-data {
  padding-left: ($grid-spacing-unit / 2);
  padding-right: ($grid-spacing-unit / 2);

  @include mq-min-width($bp-lap) {
    padding-left: $grid-spacing-unit;
    padding-right: $grid-spacing-unit;
  }

  @include mq-min-width($bp-desk-plus) {
    display: flex;
  }

  &__left {
    margin-bottom: $grid-spacing-unit;

    @include mq-min-width($bp-desk-plus) {
      flex-basis: 50%;
      margin-bottom: 0;
      padding-right: $grid-spacing-unit;
    }

    @include mq-min-width($bp-desk-mid) {
      padding-right: ($grid-spacing-unit * 2);
    }

    @include mq-min-width($bp-desk-lg) {
      padding-right: ($grid-spacing-unit * 3);
    }
  }

  &__right {
    @include mq-min-width($bp-desk-plus) {
      flex-basis: 50%;
      padding-left: $grid-spacing-unit;
    }
  }

  h2 {
    font-family: $font-stack-omnes;
    font-size: (20rem/16);
    font-style: italic;
    font-weight: normal;

    @include mq-min-width($bp-desk-plus) {
      max-width: 330px;
    }

    @at-root {
      .results-data__right h2 {
        @include mq-min-width($bp-desk-plus) {
          margin-left: auto;
          text-align: right;
        }
      }
    }
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding-left: 0;

    li {
      flex-basis: 50%;

      &:first-child {
        padding-right: 16px;
      }

      &:last-child {
        padding-left: 16px;
      }
    }
  }

  &__number {
    color: transparentize($green-2018, 0.4);
    display: block;
    font-weight: 900;
    letter-spacing: -0.045em;

    &--left {
      font-size: (60rem/16);
      line-height: 1;

      @include mq-min-width($bp-palm) {
        font-size: (72rem/16);
      }

      @include mq-min-width($bp-palm-lg) {
        font-size: (80rem/16);
      }
    }

    &--right {
      font-size: (28rem/16);
      line-height: 1;

      @include mq-min-width($bp-palm) {
        font-size: (30rem/16);
      }

      @include mq-min-width($bp-palm-lg) {
        font-size: (40rem/16);
      }

      @include mq-min-width($bp-lap) {
        font-size: (50rem/16);
      }

      @include mq-min-width($bp-lap-lg) {
        font-size: (60rem/16);
      }

      @include mq-min-width($bp-desk-plus) {
        font-size: (40rem/16);
      }

      @include mq-min-width($bp-desk-mid) {
        font-size: (50rem/16);
      }

      @include mq-min-width($bp-desk-lg) {
        font-size: (60rem/16);
      }
    }
  }

  &__text {
    display: block;
    font-family: $font-stack-omnes;
    font-style: italic;
    font-weight: normal;
    max-width: 220px;

    .results-data__right & {
      @include mq-min-width($bp-desk-lg) {
        margin-left: auto;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__data-points {
    display: flex;
    background-image: url('/lib/svg/ico-brown-arrow.svg');
    background-position: 48% 27px;
    background-repeat: no-repeat;

    @include mq-min-width($bp-palm-lg) {
      background-position: 48% 32px;
    }

    @include mq-min-width($bp-lap) {
      background-position: 49% 38px;
    }

    @include mq-min-width($bp-lap-lg) {
      background-position: 46% 42px;
    }

    @include mq-min-width($bp-desk) {
      background-position: 37% 42px;
    }

    @include mq-min-width($bp-desk-plus) {
      background-position: 47% 32px;
    }

    @include mq-min-width($bp-desk-mid) {
      background-position: 49% 38px;
    }

    @include mq-min-width($bp-desk-lg) {
      background-position: 54.5% 42px;
      justify-content: flex-end;
    }
  }

  &__data-point {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    @include mq-min-width($bp-desk) {
      flex-basis: 40%;
    }

    @include mq-min-width($bp-desk-plus) {
      flex-basis: 50%;
    }

    @include mq-min-width($bp-desk-lg) {
      flex-basis: 45%;
    }

    &:first-child {
      padding-right: 12px;
    }

    &:last-child {
      padding-left: 12px;
    }
  }

  &__from-to {
    color: $green-2018;
    font-size: (13rem/16);
    font-style: italic;
    font-weight: 500;
    letter-spacing: -0.045em;
    line-height: 1;
    text-transform: lowercase;
  }
}