// =============================================================================
// Navigation
// =============================================================================

.nav-block {
  position: relative;

  @include mq-min-width($bp-lap-lg) {
    display: inline-block;

    &:before {
      border-top: 4px solid $red-2018;
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  a {
    text-align: left;

    &:hover {
      color: $teal;
    }
  }
}

.nav {
  background-color: #fff;
  border-top: 4px solid $teal-light;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition-duration: 0.1s;
  transition-property: transform;
  width: 90%;
  z-index: 15000;
  -webkit-overflow-scrolling: touch;

  @include mq-min-width($bp-lap-lg) {
    background-color: transparent;
    border: none;
    padding: ($grid-spacing-unit / 2) 0;
    position: static;
    transform: translateX(0);
    width: auto;
  }

  @include mq-min-width($bp-desk-mid) {
    padding-top: ($grid-spacing-unit / 1.5);
  }

  &.open {
    box-shadow: 0 0 40px 4px rgba(0,0,0,.22);
    transform: translateX(0);

    @include mq-min-width($bp-lap-lg) {
      box-shadow: none;
    }
  }

  li {
    display: block;
  }

  .nav__close-btn {
    background-color: $green-light;
    padding: .85rem 1rem;
    position: absolute;
    right: $grid-spacing-unit / 4;
    top: 14px;
    transform: scale(0.75);

    @include mq-min-width(370px) {
      transform: scale(1);
      right: $grid-spacing-unit / 2;
      top: $grid-spacing-unit / 1.5;
      padding: .85rem 1.1rem;
    }

    @include mq-min-width(420px) {
      top: $grid-spacing-unit;
    }

    @include mq-min-width($bp-lap-lg) {
      display: none;
    }

    svg {
      fill: $green-2018;
    }

    &:hover {
      svg {
        fill: $grey;
      }
    }
  }

}

.nav__list {
  font-size: 0;
  padding-top: 8rem;

  @include mq-min-width($bp-lap-lg) {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0;
    padding-right: $grid-spacing-unit;
  }

  @include mq-min-width($bp-desk-plus) {
    flex-wrap: nowrap;
    padding-right: ($grid-spacing-unit * 1.5);
  }

  > li {
    border-bottom: 1px solid $beige-light;
    font-size: 1rem;

    @include mq-min-width($bp-lap-lg) {
      border-bottom: none;
      flex: auto;
      text-align: right;
      width: 50%;

      .pg-home & {
        opacity: 0;
      }
    }

    @include mq-min-width($bp-desk-plus) {
      text-align: left;
      width: auto;

      &:not(:first-child) {
        margin-left: ($grid-spacing-unit / 2.75);
      }
    }

    @include mq-min-width($bp-desk-mid) {
      &:not(:first-child) {
        margin-left: ($grid-spacing-unit * 1.5);
      }
    }

    @include mq-min-width($bp-desk-lg) {
      &:not(:first-child) {
        margin-left: ($grid-spacing-unit * 2.5);
      }
    }
  }

  li {
    position: relative;

    ul {
      display: none;
    }
  }

  .nav__item--visible-children {
    @include mq-max-width(768px) {
      > ul {
        display: block;
      }
    }
  }

  .selected {
    > a {
      color: $green-2018;

      @include mq-min-width($bp-lap-lg) {
        border-bottom: 1px solid #4fa392;
        color: white;
      }
    }

    > ul {
      display: block;

      @include mq-min-width($bp-lap-lg) {
        display: none;
      }
    }
  }

  a {
    color: $brown-dark;
    display: block;
    font-family: $font-stack-omnes;
    font-size: 1.3rem;
    font-weight: 500;
    height: 100%;
    padding-top: (10rem/16);
    padding-bottom: (8rem/16);
    padding-left: $grid-spacing-unit / 1.5;
    text-decoration: none;
    width: calc(100% - 60px);

    @include mq-min-width($bp-palm-lg) {
      padding-left: $grid-spacing-unit;
      width: calc(100% - 70px);
    }

    @include mq-min-width($bp-lap-lg) {
      border-bottom: 1px solid transparent;
      color: #fff;
      font-size: (19rem/16);
      padding: ($grid-spacing-unit / 4) 0 0;
      width: auto;
    }

    @include mq-min-width($bp-desk-plus) {
      position: relative;
    }

    &:hover,
    &:focus {
      @include mq-min-width($bp-lap-lg) {
        color: #fff;
        border-bottom: 1px solid $green-2018;
      }

      @include mq-min-width($bp-desk-plus) {
        span {
          color: #94afbd;
          display: block;
          font-size: (15rem/16);
          left: 0;
          position: absolute;
          top: calc(100% + 10px);
          white-space: nowrap;
        }
      }
    }

    span {
      display: none;
    }
  }

  ul {
    @include mq-min-width($bp-palm-lg) {
      padding-left: 1.5rem;
    }
  }

  // Level 1

  ul {
    a {
      font-size: 1rem;
      font-family: $font-stack-aktiv;
      width: 100%;
    }
  }

  // Level 2

  ul ul {
    a {
      color: lighten( $brown-dark, 30% );
      font-weight: normal;
      font-size: 1.1rem;
      font-weight: bold;
      padding-top: $grid-spacing-unit / 6;
      padding-bottom: $grid-spacing-unit / 6;
    }
  }

  // Level 3
  ul ul ul {
    padding-left: 0;

    a {
      font-size: .85rem;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;

      &:before {
        content:"—";
        font-weight: 400;
        margin-right: $grid-spacing-unit / 4;
      }
    }
  }
}

.nav__toggle-children {
  position: absolute;
  right: 1.5rem;
  top: ($grid-spacing-unit / 5);

  @include mq-min-width($bp-palm-lg) {
    right: $grid-spacing-unit;
  }

  @include mq-min-width($bp-lap-lg) {
    display: none;
  }
}

.nav__toggle-children {

  button {
    color: #a2a2a2;
    font-size: 1.5rem;
    padding: 0 10px;
    transition-property: transform;
    transition-duration: 200ms;

    &:focus {
      outline: none;
    }
  }
}

.nav__item--visible-children {

  .nav__toggle-children {

    button {
      transform: rotate(45deg);
    }
  }

  & + ul {
    display: block;
    list-style-type: none;
    padding-bottom: 20px;
    padding-left: 0;
    visibility: visible;
  }
}

// ============================================================================
// The inner nav scrollable container.
// ============================================================================

.nav__scroll {
  height: 100%;
  overflow-y: auto;
  position: absolute;
  width: 100%;

  @include mq-min-width($bp-lap-lg) {
    height: auto;
    position: static;
    width: auto;
  }

  @include mq-min-width($bp-desk-plus) {
    overflow: visible;
  }
}

// ============================================================================
// Utility Nav
// ============================================================================

.utility-nav {
  display: none;
  overflow: hidden;

  @include mq-min-width($bp-lap-lg) {
    display: block;
  }

  a {
    color: #fff;
    font-size: (13rem/16);
    font-weight: 500;
    padding: ($grid-spacing-unit / 1.4) ($grid-spacing-unit / 4) ($grid-spacing-unit / 3) ($grid-spacing-unit / 4);
    text-decoration: none;

    @include mq-min-width($bp-desk) {
      padding-left: ($grid-spacing-unit / 2.5);
      padding-right: ($grid-spacing-unit / 2.5);
    }

    @include mq-min-width($bp-desk-plus) {
      padding-left: ($grid-spacing-unit / 2.25);
      padding-right: ($grid-spacing-unit / 2.25);
    }

    @include mq-min-width($bp-desk-lg) {
      padding-left: ($grid-spacing-unit / 1.5);
      padding-right: ($grid-spacing-unit / 1.5);
    }

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: underline;
    }
  }

  .icn-search {
    &:hover,
    &:focus {
      path {
        stroke: #fff;
      }
    }
  }

  // Donate button set in from top.

  .donate {
    margin-left: $grid-spacing-unit / 4;

    @include mq-min-width($bp-desk) {
      margin-left: $grid-spacing-unit / 2;
    }

    a {
      background-color: $red-2018;
      background-image: url('/lib/svg/ico-gift-box.svg');
      background-position: 12px 28px;
      background-repeat: no-repeat;
      background-size: 15px 14px;
      border-radius: 0px 0px $border-radius $border-radius;
      color: #fff;
      font-weight: 700;
      padding-left: $grid-spacing-unit;
      position: relative;

      @include mq-min-width($bp-desk) {
        background-position: 20px 28px;
        padding-left: ($grid-spacing-unit * 1.25);
      }
    }
  }
}

.utility-nav__search-input {
  font-size: 90%;
  margin-bottom: -4px;
  margin-right: 0.5em;
  padding: 4px 8px;
  position: relative;
  top: 2px;
  width: 160px;

  &:focus {
    outline: none;
  }
}

.utility-nav__search-submit {
  width: 20px;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    path {
      stroke: $green-2018;
    }
  }
}

.icn-search {
  height: 20px;
  width: 20px;
}

// ============================================================================
// Mobile-nav-only static nav, added in Jan 2019
// ============================================================================

.nav__mobile {
  margin-top: $grid-spacing-unit;
  text-align: center;
  padding-left: 0;

  @include mq-min-width($bp-lap-lg) {
    display: none;
  }

  li + li {
    margin-top: ($grid-spacing-unit / 2);
  }
}

// ============================================================================
// Subnav - horizontal nav exposed at secondary level.
// ============================================================================

.sub-nav {
  display: none;
  line-height: 1;
  padding-top: $grid-spacing-unit / 2;
  padding-bottom: $grid-spacing-unit / 2.4;

  @include mq-min-width($bp-lap-lg) {
    background-color: rgba(0, 138, 157, .85);
    display: block;
    padding-left: $grid-spacing-unit;
    padding-right: $grid-spacing-unit;

    .pg-landing & {
      transform: translatey(-100%); // Moves it out of the .content area by it's own height. SMall clac gap appears hence 99.5%
      border-bottom: 30px solid #fff;
    }
  }

  @include mq-min-width($bp-desk-plus) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li {
    padding-right: $grid-spacing-unit / 4;

    @include mq-min-width($bp-desk-mid) {
      padding-top: $grid-spacing-unit / 4;
      padding-bottom: $grid-spacing-unit / 4;
      padding-right: $grid-spacing-unit;
    }
  }

  a {
    color: white;
    font-family: $font-stack-omnes;
    font-size: 1.0625rem;
    font-weight: 700;
    letter-spacing: 0.025rem;
    padding-top: (8rem/16);
    padding-bottom: (6rem/16);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      border-bottom: 1px solid $beige-2018;
      margin-bottom: -1px;
    }
  }

  .selected {
    a {
      border-bottom: 1px solid $beige-2018;
      margin-bottom: -1px;
    }
  }
}

// ============================================================================
// Subnav - showed in sidebar
// ============================================================================

.sub-nav--sidebar {
  margin-top: $grid-spacing-unit;
  margin-bottom: $grid-spacing-unit;
  font-size: 1rem;

  @include mq-min-width($bp-lap-lg) {
    margin-left: -1.5rem;
  }

  @include mq-min-width($bp-desk-plus) {
    margin-left: 0;
  }

  h1 {
    @include mq-min-width($bp-lap-lg) {
      display: none;
    }
  }

  ul {
    padding: 0;
    list-style: none;
  }

  a {
    padding: $grid-spacing-unit / 2 $grid-spacing-unit / 1.63 $grid-spacing-unit / 2.2;
    border-top: 1px solid lighten($green-2018, 30%);
    font-size: 1.25rem;
    display: block;
    background-image: url(/lib/svg/arrow-r.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: $teal-light;
      background-color: $green-2018-light;
    }

    @include mq-min-width($bp-lap-lg) {
      font-size: 1rem;
      background-image: none;
      padding-left: $element-spacing-unit / 2;
    }

    @include mq-min-width($bp-desk-plus) {
      padding: $grid-spacing-unit / 2 $grid-spacing-unit / 1.63 $grid-spacing-unit / 2.2;
    }

    @include mq-min-width($bp-desk-mid) {
      background-image: url(/lib/svg/arrow-r.svg);
    }
  }

  ul ul a {
    font-size: .9rem;
    padding-left: $grid-spacing-unit;
    padding-top: $grid-spacing-unit / 4;
    padding-bottom: 0;
    border: none;
    background: none;

    &:before {
      content:"—";
      font-weight: 400;
      margin-right: $grid-spacing-unit / 4;
    }

    &:hover {
      background: none;
    }
  }

  ul .selected {
    > a {
      color: $teal-light;
      background-color: $green-2018-light;
    }
  }
}

.logo--name {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
}


