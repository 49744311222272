// =============================================================================
// Borrowed from h5bp.com.
// =============================================================================

.theme--default {

  // .logo a {
  //   background-color: #9cb33c;
  // }

  .nav {
      .h-list--fit {

      a {
        background-color: lighten($dark-grey, 10%);
        color: #fff;

        &:hover {
          background-color: darken($dark-grey, 10%);
        }
      }
    }
  }

}




