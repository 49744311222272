// =============================================================================
// Landing page intro text
// =============================================================================

.lp-intro {
  background-repeat: no-repeat;
  background-position: center center;
  padding: 2rem 1.125rem;
  text-align: center;

  @include mq-min-width($bp-lap-lg) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
    padding: 0 2rem;
    transform: translatey(-40%);

    @include ie-11-only {
      display: block;
    }
  }

  @include mq-min-width($bp-desk) {
    padding: 0 8%;
  }

  // Background image
  &--bridges {
    background-image: url("lib/img/ico-bridges-in-circle.png");

    @include mq-min-width($bp-lap-lg) {
      background-image: url("/lib/img/ico-bridges-in-circle.png");
    }
  }

  h1 {
    color: #4da392;
    font-size: .9rem;
    font-weight: bold;
    text-transform: uppercase;

    @include mq-min-width($bp-lap) {

    }

    @include mq-min-width($bp-desk) {
      font-size: 1rem;
      margin-bottom: .5rem;
    }
  }

  p {
    color: #424141;
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.6;
    margin: 0;

    @include mq-min-width($bp-lap) {
      font-size: 1.35rem;
    }

    @include mq-min-width($bp-desk) {
      font-size: 1.5rem;
    }

    & + p {
      margin-top: 1.5rem;
    }
  }

  .btn {
    font-size: 1rem;
  }
}
