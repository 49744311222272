$exchange-lede-overhang-height: (30rem/16);

.exchange-lede {
  @include mq-min-width($bp-lap-lg) {
    display: flex;
    position: relative;

    &::before {
      background-color: #fff;
      content: '';
      display: block;
      height: $exchange-lede-overhang-height;
      left: $grid-spacing-unit;
      position: absolute;
      top: 0;
      width: calc(100% - (#{$grid-spacing-unit} * 2));
      z-index: 1;
    }
  }

  &__left,
  &__right {
    padding: $grid-spacing-unit ($grid-spacing-unit / 2);

    @include mq-min-width($bp-lap) {
      padding: ($grid-spacing-unit * 2) $grid-spacing-unit;
    }

    @include mq-min-width($bp-lap-lg) {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: center;
    }

    @include mq-min-width($bp-desk-plus) {
      padding: ($grid-spacing-unit * 3) ($grid-spacing-unit * 1.5);
    }

    @include mq-min-width($bp-desk-mid) {
      padding: ($grid-spacing-unit * 3) ($grid-spacing-unit * 2);
    }

    @include mq-min-width($bp-desk-lg) {
      padding: ($grid-spacing-unit * 4) ($grid-spacing-unit * 3) ($grid-spacing-unit * 5);
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  &__left {
    background-color: $blue-2018;
    background-image: url('/lib/img/exchange-lede-venn-bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $beige-2018;
    font-family: $font-stack-omnes;
    font-size: (28rem/16);
    font-weight: 400;
    line-height: 1.2;
    position: relative;
    text-align: center;

    @include mq-min-width($bp-palm-lg) {
      font-size: (35rem/16);
    }

    &::after {
      background-color: #fff;
      content: '';
      height: 40px;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translateY(-50%);
      width: 2px;

      @include mq-min-width($bp-lap-lg) {
        content: none;
      }
    }

    > p {
      height: 140px;
      margin-bottom: 0;

      @include mq-min-width($bp-lap-lg) {
        height: 190px;
      }

      @include mq-min-width($bp-desk-plus) {
        height: 140px;
      }
    }

    strong {
      color: #fff;
      display: block;
      font-size: (38rem/16);
      font-weight: 500;

      @include mq-min-width($bp-palm-lg) {
        font-size: (44rem/16);
      }
    }
  }

  &__right {
    background-color: $beige-2018;

    h2 {
      font-family: $font-stack-omnes;
      font-size: (38rem/16);
      font-weight: 500;

      @include mq-min-width($bp-palm-lg) {
        font-size: (55rem/16);
      }

      @include mq-min-width($bp-lap-lg) {
        position: relative;
      }

      &::before {
        @include mq-min-width($bp-lap-lg) {
          background-color: #fff;
          content: '';
          height: 2px;
          position: absolute;
          right: calc(100% + (#{$grid-spacing-unit} / 2));
          top: 30px;
          width: 40px;
        }

        @include mq-min-width($bp-desk-plus) {
          width: 72px;
        }

        @include mq-min-width($bp-desk-mid) {
          width: 106px;
        }

        @include mq-min-width($bp-desk-lg) {
          width: 174px;
        }
      }
    }

    .btn {
      padding-left: 2rem;
      padding-right: 2rem;

      @include mq-min-width($bp-palm-lg) {
        padding-left: 3.375rem;
        padding-right: 3.375rem;
      }

      @include mq-min-width($bp-lap-lg) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @include mq-min-width($bp-desk) {
        padding-left: 3.375rem;
        padding-right: 3.375rem;
      }
    }
  }

  &__icon {
    display: inline-block;
    margin: 40px auto;
    position: relative;

    @include mq-min-width($bp-lap-lg) {
      margin-bottom: 80px;
      margin-top: 80px;
    }

    @include mq-min-width($bp-desk-lg) {
      margin-bottom: 100px;
      margin-top: 140px;
    }

    &::before,
    &::after {
      content: '';
      border-radius: 50%;
      left: 50%;
      height: 84px;
      position: absolute;
      top: 50%;
      width: 84px;
    }

    &::before {
      border: 2px solid rgba(255,255,255,0.7);
      transform: translate(-50%, -50%) scale(1.25);
    }

    &::after {
      border: 2px solid rgba(255,255,255,0.1);
      transform: translate(-50%, -50%) scale(1.5);
    }
  }

  &__subtitle {
    color: #fff;
    font-weight: 700;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &__right-p {
    font-size: (20rem/16);
    margin-bottom: $grid-spacing-unit;

    @include mq-min-width($bp-palm-lg) {
      font-size: (23rem/16);
      margin-bottom: ($grid-spacing-unit * 2);
    }
  }
}