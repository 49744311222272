// =============================================================================
// List options
// =============================================================================

ul,
ol {
  padding-left: 1.125rem;
}

ol > li {
  list-style: decimal;
  padding-left: 1rem;
}

// Block list (used on navs).

.b-list {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {

    &,
    > a {
        display: block;
    }
  }
}

.h-list {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {

    &,
    > a {
        display: inline-block;
    }
  }
}

.nav .h-list {

}

// Margins set to the right, items aligned right.

.h-list--rm {
  text-align: right;

  > li {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

// Margins set to the left, items aligned left.

.h-list--lm {
  text-align: right;

  > li {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

// Pipe separated list.

.h-list--piped {
  line-height: 1;

  li {
    border-right: 2px solid $green-2018;
    padding-left: $grid-spacing-unit / 2.5;
    padding-right: $grid-spacing-unit / 2.5;
  }
}

.piped--l {
  li {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border: none;
    }
  }
}

.piped--r {
  text-align: right;
  li {
    &:last-child {
      border: none;
      padding-right: 0;
    }
  }
}




// Center links, subclassed <ul class="h-list  h-list--center">

.h-list--center {
  text-align: center;
}


// Forces inline-block items to be flush, subclassed
// <ul class="h-list  h-list--flush">

.h-list--flush {
  font-size: 0;

  > li {
    font-size: 1rem;
    letter-spacing: normal;
    word-spacing: normal;
  }
}

// Turns list into table and cells to:
// [1] Occupy 100% of available space
// [2] Match column heights
// <ul class="h-list--fit">

.h-list--fit {
  display: table;
  text-align: center;
  width: 100%;

  > li {
    display: table-cell;


    > a {
        display: block;
    }
  }
}

.strip-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
