// =============================================================================
// Image treatments
// =============================================================================



.media {
  position: relative;
}

.media--is-video {
  cursor: pointer;

  iframe {
    display: none;
  }
}

.media--has-play {

  &:before {
    background: #554b4a url('/lib/svg/media-arrow.svg') no-repeat 24px center;
    background-size: 20%;
    border: 8px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 28px rgba(15,15,15,.28),
                0 1px 1px rgba(33,58,80,.52);
    color: #fff;
    content: '';
    font-size: (25rem/16);
    height: 70px;
    left: 50%;
    padding: 9px 0 0 3px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
  }

  &:hover {
    &:before {
      background-color: $green-2018;
      border-color: $green-2018;
    }
  }
}