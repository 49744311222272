$results-lede-overhang-height: (30rem/16);

.results-lede {
  @include mq-min-width($bp-lap-lg) {
    display: flex;
    margin-top: $grid-spacing-unit * 2;
    position: relative;
    overflow: hidden;

    .pg-landing & {
      margin-top: 0;
    }

    &::before {
      background-color: #fff;
      content: '';
      display: block;
      height: $results-lede-overhang-height;
      left: $grid-spacing-unit;
      position: absolute;
      top: 0;
      width: calc(100% - (#{$grid-spacing-unit} * 2));
      z-index: 2;
    }
  }

  &__left,
  &__right {
    @include mq-min-width($bp-lap-lg) {
      flex-basis: 50%;
    }
  }

  &__right {
    background-image: linear-gradient(135deg, $beige-2018 70%, #d69480 105%);
    padding: ($grid-spacing-unit * 1.25) ($grid-spacing-unit / 2) ($grid-spacing-unit * 1.75);
    position: relative;

    @include mq-min-width($bp-lap) {
      padding-left: $grid-spacing-unit;
      padding-right: $grid-spacing-unit;
    }

    @include mq-min-width($bp-lap-lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: $grid-spacing-unit;
      padding-left: 0;
      padding-right: 0;
    }

    @include mq-min-width($bp-desk) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &__bg-img {
    background-position: center center;
    background-size: cover;
    filter: blur(10px);
    height: 100%;
    left: 0;
    opacity: 0.1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;

    .btn {
      padding-left: 2rem;
      padding-right: 2rem;

      @include mq-min-width($bp-palm-lg) {
        padding-left: 3.375rem;
        padding-right: 3.375rem;
      }

      @include mq-min-width($bp-lap-lg) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @include mq-min-width($bp-desk) {
        padding-left: 3.375rem;
        padding-right: 3.375rem;
      }
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  &__result {
    font-family: $font-stack-omnes;
    position: relative;
    text-align: center;
    z-index: 2;
  }

  &__number {
    color: #fff;
    display: block;
    font-size: (120rem/16);
    font-weight: 500;
    line-height: 0.8;

    @include mq-min-width($bp-lap) {
      font-size: (206rem/16);
    }

    @include mq-min-width($bp-lap-lg) {
      font-size: (90rem/16);
    }

    @include mq-min-width($bp-desk-plus) {
      font-size: (140rem/16);
    }

    @include mq-min-width($bp-desk-mid) {
      font-size: (206rem/16);
    }
  }

  &__title {
    color: $red-2018;
    display: block;
    font-size: (28rem/16);
    font-weight: 500;
    margin-top: $grid-spacing-unit;
    text-transform: uppercase;

    @include mq-min-width($bp-lap) {
      font-size: (36rem/16);
    }

    @include mq-min-width($bp-lap-lg) {
      font-size: (28rem/16);
      margin-top: ($grid-spacing-unit / 2);
    }

    @include mq-min-width($bp-desk-plus) {
      font-size: (36rem/16);
      margin-top: $grid-spacing-unit;
    }
  }

  &__details {
    color: #5f5347;
    display: block;
    font-style: italic;
    font-weight: 400;
    font-size: (20rem/16);
    margin: 0 auto ($grid-spacing-unit / 1.5);
    max-width: 275px;

    @include mq-min-width($bp-lap) {
      font-size: (26rem/16);
      max-width: 375px;
    }

    @include mq-min-width($bp-lap-lg) {
      font-size: (20rem/16);
      margin-bottom: ($grid-spacing-unit / 3);
      max-width: 275px;
    }

    @include mq-min-width($bp-desk-plus) {
      font-size: (26rem/16);
      margin-bottom: ($grid-spacing-unit / 1.5);
      max-width: 375px;
    }
  }

  &__example {
    display: none;
    visibility: hidden;

    @include mq-min-width(1500px) {
      height: 70%;
      display: block;
      left: 0;
      position: absolute;
      top: -50px;
      visibility: visible;
      width: 100%;
      z-index: 1;

      &::before {
        background-color: #fff;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        right: ($grid-spacing-unit * 1.5);
        top: 0;
        transform: rotate(-14.25deg) translateY(14px);
        transform-origin: right top;
        width: 82%;
      }

      &-before,
      &-after {
        display: inline-block;
        font-family: $font-stack-omnes;
        font-size: (20rem/16);
        font-weight: 500;
        position: absolute;
        text-align: center;
        text-transform: uppercase;

        &::before {
          background-color: $red-2018;
          border: 8px solid #fff;
          border-radius: 50%;
          content: '';
          display: block;
          height: 30px;
          margin: 0 auto 8px;
          width: 30px;
        }

        strong {
          display: block;
          font-weight: 700;
        }
      }

      &-before {
        left: ($grid-spacing-unit * 2);
        top: 150px;
      }

      &-after {
        right: $grid-spacing-unit;
        top: 0;
      }
    }
  }
}