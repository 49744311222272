$slider-layout-swap: 820px;

.slider {
  background: $green-2018 url('/lib/img/bridge-1x.png') no-repeat right bottom;
  padding: 18px 18px 26px;

  @include mq-2x() {
    background-image: url('/lib/img/bridge-2x.png');
    background-size: 453px 200px;
  }

  &__caption {
    background-color: $teal-2018;
    color: white;
    font-size: 1rem;
    line-height: 1.125;
    padding: 10px 12px;

    strong {
      color: #fff;
    }
  }

  &__title {
    color: rgba(255,255,255,.9);
    font-family: $font-stack-omnes;
    font-size: (40rem/16);
    font-weight: 500;
    line-height: 1;
    margin-top: (14rem/16);
  }

  &__body {
    color: rgba(255,255,255,.95);
    font-size: (22rem/16);
    line-height: 1.14;
    margin-top: (14rem/16);

    > :last-child {
      margin-bottom: 0;
    }
  }

  @include mq-min-width($slider-layout-swap) {
    padding: 36px;

    &__items,
    &__content {
      width: 48%;
    }

    &__items {
      float: left;
    }

    &__content {
      float: right;
    }

    &__title {
      margin-top: 0;
    }
  }

  @include mq-min-width($bp-desk-plus) {
    &__items,
    &__content {
      width: 48.5%;
    }

    &__title {
      margin-top: (14rem/16);
    }
  }

  @include mq-min-width($content-max-width) {
    .modules & {
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }
}


// ============================================================================
// END REGULAR PROJECT-SPECIFIC STYLES.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// BEGIN SLICK.JS STYLES
// ============================================================================


/* Slider */
.slick-slider
{
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
         box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;

    &:focus {
      outline: none;
    }
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    border: none;
    border-radius: 0;
    display: block;
    padding: 0;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
@charset 'UTF-8';
/* Slider */
// .slick-loading .slick-list
// {
//     background: #fff url('./ajax-loader.gif') center center no-repeat;
// }

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    // src: url('./fonts/slick.eot');
    // src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    margin-top: -10px\9; /*lte IE 8*/
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
// .slick-slider
// {
//     margin-bottom: 30px;
// }

.slick-dots
{
    background-color: rgba(0,0,0,.6);
    border-radius: 40px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);

    display: block;
    margin-bottom: 0;

    // width: 100%;
    padding: 7px 6px 9px;

    list-style: none;
    line-height: 0;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    // width: 20px;
    // height: 20px;
    margin: 0 4px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 7px;
    height: 7px;
    padding: 0;

    cursor: pointer;

    color: transparent;
    border: 0;
    border-radius: 50%;
    outline: none;
    background: rgba(255,255,255,.5);
}

.slick-dots li.slick-active button
{
    background: $green-2018;
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
// .slick-dots li button:hover:before,
// .slick-dots li button:focus:before
// {
//     opacity: 1;
// }
// .slick-dots li button:before
// {
//     font-family: 'slick';
//     font-size: 6px;
//     line-height: 20px;

//     position: absolute;
//     top: 0;
//     left: 0;

//     width: 20px;
//     height: 20px;

//     content: '•';
//     text-align: center;

//     opacity: .25;
//     color: black;

//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }
// .slick-dots li.slick-active button:before
// {
//     opacity: .75;
//     color: black;
// }
