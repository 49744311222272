// =============================================================================
// Modules
// =============================================================================

// The grid system and elemnt rules are scoped to the whole document
// Some of these rules need to be modifed for use in the content
// modules / grid.


.module {
  border-bottom: 1px solid #dededf;

  &:last-child {
    border-bottom: 0;
  }

  img {
    width: 100%
  }

  h1, h2, h3 {
    font-family: $font-stack-omnes;
    font-weight: 500;
  }

  // Base font size for modules set here. Can't set on module owing to
  // display: inline-block grid issue:
  // https://css-tricks.com/fighting-the-space-between-inline-block-elements/

  .grid__item {
    font-size: 1.35rem;

    // On small screen, when a grid__item is followed by another
    // .grid__item add a margin.

    & + .grid__item {
      margin-top: $element-spacing-unit;

      @include mq-min-width($bp-lap-lg) {
        margin-top: 0;
      }

    }

    // A text block shares the same font size as full width modules.
    // For modules inside a column, **not** inside the text block,
    // we reduce the font size.

    .grid__item:not(.text-block){
      font-size: 1.1rem;
    }
  }

  h2 {
    font-size: 2.375rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.3125rem;
  }

  h5 {
    font-size: 1rem;
  }

  .tertiary & {

    .module {

      @include mq-min-width($bp-desk-mid) {
        margin-left: $grid-spacing-unit;
      }

      // H1 inside a module within the tertiary column.

      h2 {
        font-size: 1.5rem;

        @include mq-min-width($bp-lap-lg) {
          font-size: 2rem;
        }
      }

      // When in the tertiary column, the first h1 needs to be larger.
      &:first-of-type h1 {
        font-size: 2.5rem;

        & + h2 {
          margin-top: $element-spacing-unit * 2;
        }

        @include mq-min-width($bp-lap-lg) {
          font-size: 3.5625rem;
        }
      }
    }
  }

  .intro {
    padding-bottom: $element-spacing-unit;

    + .grid {
      padding-top: 0;
    }

    @include mq-min-width($bp-lap-lg) {
      padding-bottom: $element-spacing-unit * 1.5;
    }

    .tertiary & {
      padding-bottom: $element-spacing-unit;
    }

    h1 {
      font-size: 2.5rem;

      @include mq-min-width($bp-lap-lg) {
        font-size: 3.25rem;
      }

      .tertiary & {
        font-size: 2.1rem;

        @include mq-min-width($bp-lap-lg) {
          font-size: 2.45rem;
        }
      }
    }

    p {
      font-size: 1.45rem;
      line-height: 1.47826087;

      @include mq-min-width($bp-lap-lg) {
        font-size: 1.75rem;
      }

      .tertiary & {
        font-size: 1.35rem;
      }
    }
  }
}


// Tweaks to type sizes when in a 3 or 4 col module inside a tertiary column.

.module.three-col,
.module.four-col {

  h2 {
    margin-bottom: .5rem;
    font-size: 1.75rem;

    .tertiary & {
      font-size: 1.5rem;

      @include mq-min-width($bp-lap-lg) {
        font-size: 1.2rem;
      }

      @include mq-min-width($bp-desk-mid) {
        font-size: 1.5rem;
      }

    }
  }
}



// These rules equalize margin between elements for 3 and 4 columns modules.
// Since 3 and 4 col layouts break from 100% width to 50/50 and then 1/3 and 1/4
// at a different time, the rule above spacing maodules at 100% need to be overidden.


.module.three-col {
  .grid__item:nth-child(n+4) {
    margin-top: $grid-spacing-unit;
  }
  @include mq-min-width($bp-palm) {
    .grid__item:nth-child(-n+2) {
      margin-top: 0;
    }
  }
}

.module.four-col {
  .grid__item:nth-child(n+5) {
    margin-top: $grid-spacing-unit;
  }
  @include mq-min-width($bp-palm) {
    .grid__item:nth-child(-n+2) {
      margin-top: 0;
    }
  }

  @include mq-min-width($bp-lap-lg) {
    .grid__item:nth-child(n+3) {
      margin-top: $grid-spacing-unit;
    }
  }

  @include mq-min-width(900px) {
    .grid__item:nth-child(-n+4) {
      margin-top: 0;
    }
  }

  .tertiary & {
    h2 + p,
    figure + p {
      @include mq-min-width($bp-lap-lg) {
        font-size: .85rem;
      }

      @include mq-min-width($bp-desk-mid) {
        font-size: 1.1rem;
      }
    }
  }
}

// Rules inside a single nested column of text.


.module {

  .text-block {
    font-size: 1.1rem;

    @include mq-min-width($bp-desk-mid) {
      padding-right: 5rem;
    }

    h2 {
      margin-top: $element-spacing-unit;
    }

    h3 {
      font-size: 1.3125rem;
      margin-bottom: .5rem;

      & + ul,
      & + ol {
        margin-top: 1rem;
      }
    }

    h4 {
      font-size: 1rem;
    }

    ul ul {
      margin-top: $element-spacing-unit / 2.5;
    }

    li {
      margin-bottom: $element-spacing-unit / 2.5;
    }
  }
}

.module.page-header--w {
  border: none;

  @include mq-min-width($bp-desk-mid) {
    padding-right: 5rem;
  }

  + .module .grid:first-of-type {
    padding-top: 1.33333rem;
  }

  + .module .grid .text-block > h2:first-child {
    margin-top: 0;
  }

  .grid {
    padding-bottom: 0;
  }
}

