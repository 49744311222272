// =============================================================================
// Containers
// =============================================================================

.container {
  flex: 1;
  margin: 0 auto;
  max-width: $content-max-width;
  width: 100%;

  main {
    background-color: #fff;
    display: block;
  }
}

.bg-color {
  &--beige {
    background-color: $beige-2018;
  }
}

