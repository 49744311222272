// =============================================================================
// Borrowed from h5bp.com.
// =============================================================================

html {
  box-sizing: border-box;
  color: $color-text;
  font-family: $font-stack-aktiv;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: 400;
  font-style: normal;
}

body {
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;

  @include ie-11-only {
    display: block;
    min-height: auto;
  }
}

// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/

*,
*:before,
*:after {
  box-sizing: inherit;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid darken($color-background, 25%);
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}


// =============================================================================
// Project-specific global/base styles.
// =============================================================================


// Angular

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

// Links

a {
  color: $color-link;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

// Paragraphs

.intro {
  font-size: 1.5rem;
}

.intro--huge {
  font-size: 2.5rem;
}


// Images

figure {
  margin: 0
}

img {
  height: auto;
  max-width: 100%;
}

// Headings

h1, h2, h3, h4, h5, h6 {
  line-height: 1.143;
}

// h4, .h4 {
//   text-transform: uppercase;
//   letter-spacing: .05em;
// }

// Quotes

blockquote {
  margin: 0;
}

// Tables

table {
  width: 100%;
  border: 1px solid $beige;
}

td, th {
  padding: 1rem;
  font-size: 1rem;
}

th {
  background-color: $green-light;
  font-weight: 700;
  text-align: left;
}

td {
  border-bottom: 1px solid $beige;
  border-right: 1px solid $beige;

  &:last-child {
    border-right: none;
  }
}

// Foundation of spacing between elements

h1, h2, h3, h4, h5, h6, p, ol, ul, table, dl, figure, form {
  margin-top: 0;
  margin-bottom: $element-spacing-unit / 1.5;

  // .text-block & {
  //   margin-bottom: $element-spacing-unit / 1.25;
  // }
}


// Blockquotes

blockquote {
  border-left: 10px solid $green-2018;
  color: #444;
  font-size: 115%;
  padding-left: 1rem;
}


// Relationship of particular sets of elements

figure + h1,
figure + h2,
figure + h3,
figure + h4 {
  margin-top: $element-spacing-unit / 2;
}
