/*------------------------------------*\
    $GRIDS
\*------------------------------------*/
/**
 * Fluid and nestable grid system, e.g.:
 *
   <div class="grid">

       <div class="grid__item one-third">
           <p>One third grid</p>
       </div><!--

    --><div class="grid__item two-thirds">
           <p>Two thirds grid</p>
       </div><!--

    --><div class="grid__item one-half">
           <p>One half grid</p>
       </div><!--

    --><div class="grid__item one-quarter">
           <p>One quarter grid</p>
       </div><!--

    --><div class="grid__item one-quarter">
           <p>One quarter grid</p>
       </div>

   </div>
 *
 */


/**
 * Grid wrapper
 */


.grid {
  font-size: 0;
  list-style: none;
  padding-right: $grid-spacing-unit / 2;

  @include mq-min-width($bp-lap) {
    padding-right: $grid-spacing-unit;
  }

  .module & {
    padding-bottom: $grid-spacing-unit;
    padding-top: $grid-spacing-unit;

    @include mq-min-width($bp-lap) {
      padding-bottom: $grid-spacing-unit * 2;
      padding-top: $grid-spacing-unit * 2;
    }
  }

  // Remove the padding separating modules.
  // Flatten = remove bottom padding from .grid is is applied to
  // Then it removes most of the top padding from the item below.
  // Then it resets the last-child selector that removes margin from the
  // last element in a grid__item

  &.flatten {
    padding-bottom: 0;

    & + .grid {
      padding-top: $element-spacing-unit / 1.5;
    }

    .grid__item {
      > :last-child {
        margin-bottom: $element-spacing-unit / 1.5;
      }
    }
  }
}

  /**
   * Grid
   */
  .grid__item {
    display: inline-block;
    font-size: $font-size;
    padding-left: $grid-spacing-unit / 2;
    vertical-align: top;
    width: 100%;

    @include mq-min-width($bp-lap) {
      padding-left: $grid-spacing-unit;
    }

    > :last-child {
      margin-bottom: 0;
    }

    // For any grid that has more than one row.
    .rows & {
      margin-bottom: $element-spacing-unit;
    }
  }

    .grid .grid {
      margin: 0;
      margin-left: -$grid-spacing-unit / 2;

      @include mq-min-width($bp-lap) {
        margin-left: -$grid-spacing-unit;
      }

      .module & {
        padding-bottom: $grid-spacing-unit;
        padding-top: $grid-spacing-unit;

        @include mq-min-width($bp-lap) {
          padding-bottom: $grid-spacing-unit * 1.5;
          padding-top: $grid-spacing-unit * 1.5;
        }
      }
    }


.tertiary {
  > .module
    {
      > .grid:first-child {
        padding-top: 0;
      @include mq-min-width($bp-lap-lg) {
        padding-top: $grid-spacing-unit;
        padding-bottom: $grid-spacing-unit;
      }
    }
  }
}

/*------------------------------------*\
    $WIDTHS
\*------------------------------------*/
/**
 * Sizes in human readable format. These are used in conjunction with other
 * objects and abstractions found in inuit.css, most commonly the grid system
 * and faux flexbox.
 *
 * We have a mixin to generate our widths and their breakpoint-specific
 * variations.
 */

@mixin grid-setup($namespace: "") {
  /**
  * Whole
  */
  .#{$namespace}one-whole         { width:100%; }


  /**
  * Halves
  */
  .#{$namespace}one-half          { width:50%; }


  /**
  * Thirds
  */
  .#{$namespace}one-third         { width:33.333%; }
  .#{$namespace}two-thirds        { width:66.666%; }


  /**
  * Quarters
  */
  .#{$namespace}one-quarter       { width:25%; }
  .#{$namespace}two-quarters      { @extend .#{$namespace}one-half; }
  .#{$namespace}three-quarters    { width:75%; }


  /**
  * Fifths
  */
  .#{$namespace}one-fifth         { width:20%; }
  .#{$namespace}two-fifths        { width:40%; }
  .#{$namespace}three-fifths      { width:60%; }
  .#{$namespace}four-fifths       { width:80%; }


  /**
  * Sixths
  */
  .#{$namespace}one-sixth         { width:16.666%; }
  .#{$namespace}two-sixths        { @extend .#{$namespace}one-third; }
  .#{$namespace}three-sixths      { @extend .#{$namespace}one-half; }
  .#{$namespace}four-sixths       { @extend .#{$namespace}two-thirds; }
  .#{$namespace}five-sixths       { width:83.333%; }


  /**
  * Eighths
  */
  .#{$namespace}one-eighth        { width:12.5%; }
  .#{$namespace}two-eighths       { @extend .#{$namespace}one-quarter; }
  .#{$namespace}three-eighths     { width:37.5%; }
  .#{$namespace}four-eighths      { @extend .#{$namespace}one-half; }
  .#{$namespace}five-eighths      { width:62.5%; }
  .#{$namespace}six-eighths       { @extend .#{$namespace}three-quarters; }
  .#{$namespace}seven-eighths     { width:87.5%; }


  /**
  * Tenths
  */
  .#{$namespace}one-tenth         { width:10%; }
  .#{$namespace}two-tenths        { @extend .#{$namespace}one-fifth; }
  .#{$namespace}three-tenths      { width:30%; }
  .#{$namespace}four-tenths       { @extend .#{$namespace}two-fifths; }
  .#{$namespace}five-tenths       { @extend .#{$namespace}one-half; }
  .#{$namespace}six-tenths        { @extend .#{$namespace}three-fifths; }
  .#{$namespace}seven-tenths      { width:70%; }
  .#{$namespace}eight-tenths      { @extend .#{$namespace}four-fifths; }
  .#{$namespace}nine-tenths       { width:90%; }


  /**
  * Twelfths
  */
  .#{$namespace}one-twelfth       { width:8.333%; }
  .#{$namespace}two-twelfths      { @extend .#{$namespace}one-sixth; }
  .#{$namespace}three-twelfths    { @extend .#{$namespace}one-quarter; }
  .#{$namespace}four-twelfths     { @extend .#{$namespace}one-third; }
  .#{$namespace}five-twelfths     { width:41.666% }
  .#{$namespace}six-twelfths      { @extend .#{$namespace}one-half; }
  .#{$namespace}seven-twelfths    { width:58.333%; }
  .#{$namespace}eight-twelfths    { @extend .#{$namespace}two-thirds; }
  .#{$namespace}nine-twelfths     { @extend .#{$namespace}three-quarters; }
  .#{$namespace}ten-twelfths      { @extend .#{$namespace}five-sixths; }
  .#{$namespace}eleven-twelfths   { width:91.666%; }
}

@include grid-setup();



/**
 * If you have set `$responsive` to ‘true’ in `_vars.scss` then you now have
 * access to these classes. You can define at which breakpoint you’d like an
 * element to be a certain size, e.g.:
 *
 * `<div class="g  one-quarter  lap-one-half  palm-one-whole"> ... </div>`
 *
 * This would create a `div` that, at ‘desktop’ sizes, takes up a quarter of the
 * horizontal space, a half of that space at ‘tablet’ sizes, and goes full width
 * at ‘mobile’ sizes.
 *
 * Demo: jsfiddle.net/inuitcss/WS4Ge
 *
 */


@include mq-min-width($bp-palm) {
  @include grid-setup("palm-");
}

@include mq-min-width($bp-palm-lg) {
  @include grid-setup("palm-lg-");
}

@include mq-min-width($bp-lap) {
  @include grid-setup("lap-");
}

@include mq-min-width($bp-lap-lg) {
  @include grid-setup("lap-lg-");
}

@include mq-min-width($bp-desk) {
  @include grid-setup("desk-");
}

@include mq-min-width($bp-desk-mid) {
  @include grid-setup("desk-mid-");
}

@include mq-min-width($bp-desk-plus) {
  @include grid-setup("desk-plus-");
}

@include mq-min-width($bp-desk-lg) {
  @include grid-setup("desk-lg-");
}



