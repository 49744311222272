// =============================================================================
// Type.
// =============================================================================

$font-stack-omnes:    'omnes-pro', sans-serif;
$font-stack-aktiv:    'aktiv-grotesk', sans-serif;

$font-stack-mono:     monospace;
$font-size:           1rem;
$line-height:         1.4210526316;


// =============================================================================
// Colors.
// =============================================================================

// Brand
$teal-dark:           #52a291;
$teal:                #58ab97;
$teal-light:          #59ab97;
$brown-dark:          #554b4a;
$brown:               #898179;
$green:               #d9e4ca;
$green-light:         lighten($green, 10%);
$green-lighter:       #f5f7ee;
$beige:               #e5ddd7;
$grey:                #574c49;
$beige-light:         #e9e6e3;
$text-on-teal:        #e1efec;
$text-input:          #5a5a5a;
$dark-grey:           $grey;
$mid-grey:            #9f9f9f;
$mid-grey-l:          #D3D2D4;
$light-grey:          #b4b5b8;
$off-white:           rgba(255,255,255,.9);
$off-black:           rgba(0,0,0,.9);

// Added for 2018 redesign:
$teal-2018:       #00899c;
$blue-2018:       #26343d;
$green-2018:      #4fa392;
$green-2018-light: lighten($green-2018, 48%);
$red-2018:        #c0535e;
$beige-2018:      #e7ca9d;

$color-background:    white;
$color-text:          #555;
$color-link:          $green-2018;
$color-link-grey:     #808086;
$color-link-hover:    red;
$color-link-visited:  gray;


// =============================================================================
// Lines & Borders
// =============================================================================

$divider-light:       white;

// =============================================================================
// Layout.
// =============================================================================

$content-min-width:     (300rem/16);
$content-max-width:     (1500rem/16);
$bp-palm:               (401rem/16);
$bp-palm-lg:            (501rem/16);
$bp-lap:                (601rem/16);
$bp-lap-lg:             (769rem/16);
$bp-desk:               (900rem/16);
$bp-desk-plus:          (1080rem/16);
$bp-desk-lg:            (1400rem/16);

$bp-exh-member:         (1024rem/16);


$grid-spacing-unit:     $font-size * 2.25; //36
$element-spacing-unit:  $font-size * 2;
$border-radius:         3px;

// Not tied to grid
$bp-desk-mid:           (1200rem/16);



// z-index: hero

$opacity-animation-speed: 3s;