.supports-lede {
  margin-left: ($grid-spacing-unit / 2);
  margin-right: ($grid-spacing-unit / 2);
  text-align: center;

  @include mq-min-width($bp-lap) {
    margin-left: $grid-spacing-unit;
    margin-right: $grid-spacing-unit;
  }

  @include mq-min-width($bp-desk-plus) {
    background-image: url('/lib/img/supports-lede-line.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 90%;
    position: relative;
  }

  &::before,
  &::after {
    @include mq-min-width($bp-desk-plus) {
      align-items: center;
      background-color: #fff;
      border: 2px solid #e0eae7;
      border-radius: 50%;
      color: $green-2018;
      display: flex;
      font-size: (15rem/16);
      font-weight: bold;
      height: 78px;
      justify-content: center;
      letter-spacing: -0.06em;
      position: absolute;
      width: 78px;
    }
  }

  &::before {
    @include mq-min-width($bp-desk-plus) {
      content: attr(data-start-year);
      bottom: 17%;
      left: $grid-spacing-unit;
    }

    @include mq-min-width(1180px) {
      bottom: 15%;
    }

    @include mq-min-width(1280px) {
      bottom: 12%;
    }

    @include mq-min-width(1380px) {
      bottom: 9.5%;
    }

    @include mq-min-width(1480px) {
      bottom: 6.5%;
    }
  }

  &::after {
    @include mq-min-width($bp-desk-plus) {
      content: attr(data-current-year);
      right: $grid-spacing-unit;
      top: 19%;
    }

    @include mq-min-width(1180px) {
      top: 16%;
    }

    @include mq-min-width(1280px) {
      top: 13%;
    }

    @include mq-min-width(1380px) {
      top: 10%;
    }

    @include mq-min-width(1480px) {
      top: 6.5%;
    }
  }

  &__copy {
    font-size: (20rem/16);
    font-weight: 500;
    margin-bottom: $grid-spacing-unit;

    @include mq-min-width($bp-lap) {
      font-size: (30rem/16);
    }

    @include mq-min-width($bp-lap-lg) {
      margin: 0 auto;
      max-width: 1020px;
      padding: 60px 0;
      position: relative;
      z-index: 1;
    }

    @include mq-min-width($bp-desk-plus) {
      padding: 80px 0;
    }

    &::before {
      @include mq-min-width($bp-lap-lg) {
        color: #dce8e5;
        content: attr(data-support-qty);
        font-size: (280rem/16);
        font-weight: 900;
        left: 50%;
        letter-spacing: -0.06em;
        line-height: 1;
        opacity: 0.35;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -55%);
        z-index: -1;
      }

      @include mq-min-width($bp-desk-plus) {
        font-size: (318rem/16);
      }
    }
  }

  &__cta {
    margin-bottom: 0;
    position: relative;
    z-index: 2;
  }
}