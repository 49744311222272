// =============================================================================
// Header
// =============================================================================

.global-header {
  position: relative;
  padding: ($element-spacing-unit / 2.1) 0;

  @include mq-min-width($bp-lap-lg) {
    text-align: right;
    padding: 0;
  }

  @include mq-min-width($bp-desk-plus) {
    padding-bottom: ($element-spacing-unit * 1.5);
  }

  h1 {
    margin: 0;
    text-align: left;
  }

  .grid {
    padding-right: 0;
  }
}

.logo {
  color: #fff;
  display: inline-block;

  @include mq-min-width($bp-lap-lg) {
    margin-top: ($element-spacing-unit * 2);
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @include mq-min-width(950px) {
    margin-top: ($element-spacing-unit * 1.75);
  }
}

.menu {
  background-color: $green-2018 !important;
  display: block;
  padding: 1rem !important;
  position: absolute;
  right: ($grid-spacing-unit / 4);
  transform: scale(0.75);

  @include mq-min-width(370px) {
    transform: scale(1);
    right: $grid-spacing-unit / 2;
    top: $grid-spacing-unit / 5;
  }

  @include mq-min-width(420px) {
    top: $grid-spacing-unit / 3;
  }

  @include mq-min-width($bp-lap) {
    right: $grid-spacing-unit;
  }

  svg {
    fill: rgba(255,255,255,0.9);
  }

  @include mq-min-width($bp-lap-lg) {
    display: none;
  }
}

