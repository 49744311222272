// =============================================================================
// Heroes
// =============================================================================

.hero {
  align-items: center;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 275px;

  @include ie-11-only {
    display: block;
    padding-top: 150px;
  }

  .grid {
    width: 100%; // Grid loses width in Flexbox
  }

  h1 {
    color: white;
    font-family: $font-stack-omnes;
    font-size: 2.75rem;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 2rem;
    position: relative;

    @include mq-min-width($bp-lap) {
      font-size: 3.25rem;
    }

    @include mq-min-width($bp-desk-mid) {
      margin-bottom: 2.6rem;
    }

    &:after {
      content: "";
      background-color: $red-2018;
      bottom: -20px;
      left: 0;
      height: 2px;
      position: absolute;
      width: 24px;
    }
  }

  p {
    color: white;
    font-size: 1.125rem;
    font-style: italic;
    margin: 0;
  }

  &__txt {

    @include mq-min-width($bp-desk-mid) {
      margin-left: 40px;
      transform: translatey(-40px);
      width: 30%;
    }
  }

  &--approach {
    background-image: url("/lib/img/hero/hero_breakthrough-2x.jpg");
    background-position: -250px 0;

    @include mq-min-width($bp-palm) {
      background-position: -200px 0; // Adjustments like this follow.
    }
    @include mq-min-width($bp-palm-lg) {
      background-position: -100px 0; // Adjustments like this follow.
    }
  
    @include mq-min-width($bp-lap) {
      background-position: -300px 0;
      min-height: 375px;
    }
  
    @include mq-min-width($bp-lap-lg) {
      background-position: -400px 0;
      min-height: 526px;
    }
  
    @include mq-min-width($bp-desk-plus) {
      background-position: -200px 0;
    }
  
    @include mq-min-width($bp-desk-mid) {
      background-position: 0 0;
    }
  
    @include mq-min-width(1300px) {
    
    }
  
    @include mq-min-width(1500px) {
      
    }
  }

  &--research {
    background-image: url("/lib/img/hero/hero_research-2x.jpg");
    background-position: -300px 0;

    @include mq-min-width($bp-palm) {
      background-position: -200px 0; // Adjustments like this follow.
    }

    @include mq-min-width(375px) {
      background-position: -260px 0; // Adjustments like this follow.
    }

    @include mq-min-width(428px) {
      background-position: -170px 0; // Adjustments like this follow.
    }
  
    @include mq-min-width($bp-lap) {
      background-position: -240px 0;
      min-height: 375px;
    }
  
    @include mq-min-width($bp-lap-lg) {
      background-position: -500px 0;
      min-height: 526px;
    }

    @include mq-min-width($bp-desk) {
      background-position: -330px 0;
    }
  
    @include mq-min-width($bp-desk-plus) {
      background-position: -300px 0;
    }

    @include mq-min-width(1180px) {
      background-position: -260px 0;
    }
  
    @include mq-min-width($bp-desk-mid) {
      background-position: -180px 0;
    }

    @include mq-min-width(1300px) {
      background-position: 0 0;
    }
  
    h1 {
      color: $green-2018;
    }

    p {
      color: $color-text;
    }
  }

  &--direct-services {
    background-image: url("/lib/img/hero/hero_direct-2x.jpg");
    background-position: -350px 0;

    @include mq-min-width($bp-palm) {
      background-position: -250px 0; // Adjustments like this follow.
    }
    @include mq-min-width($bp-palm-lg) {
      background-position: -150px 0; // Adjustments like this follow.
    }
  
    @include mq-min-width($bp-lap) {
      background-position: -250px 0;
      min-height: 375px;
    }
  
    @include mq-min-width($bp-lap-lg) {
      background-position: -400px 0;
      min-height: 526px;
    }
  
    @include mq-min-width($bp-desk-plus) {
      background-position: -200px 0;
    }
  
    @include mq-min-width($bp-desk-mid) {
      background-position: 0 0;
    }
  }

  &--global {
    background-image: url("/lib/img/hero/hero_global-2x.jpg");
    background-position: -250px 0;

    @include mq-min-width($bp-palm) {
      background-position: -200px 0; // Adjustments like this follow.
    }
    @include mq-min-width($bp-palm-lg) {
      background-position: -100px 0; // Adjustments like this follow.
    }
  
    @include mq-min-width($bp-lap) {
      background-position: -300px 0;
      min-height: 375px;
    }
  
    @include mq-min-width($bp-lap-lg) {
      background-position: -400px 0;
      min-height: 526px;
    }
  
    @include mq-min-width($bp-desk-plus) {
      background-position: -200px 0;
    }
  
    @include mq-min-width($bp-desk-mid) {
      background-position: 0 0;
    }
  }

  &--about {
    background-image: url("/lib/img/hero/hero_about-2x.jpg");
    background-position: -100px 0;

    @include mq-min-width($bp-palm) {
      background-position: -200px 0; // Adjustments like this follow.
    }
    @include mq-min-width($bp-palm-lg) {
      background-position: -100px 0; // Adjustments like this follow.
    }
  
    @include mq-min-width($bp-lap) {
      background-position: -300px 0;
      min-height: 375px;
    }
  
    @include mq-min-width($bp-lap-lg) {
      background-position: -400px 0;
      min-height: 526px;
    }
  
    @include mq-min-width($bp-desk-plus) {
      background-position: -200px 0;
    }
  
    @include mq-min-width($bp-desk-mid) {
      background-position: 0 0;
    }
  }
}



// Hero used only on home page. No shared properties with .hero

.home-hero {
  background-blend-mode: screen; // Starts out blended with $teal
  background-color: $green-2018;
  background-position: -250px 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: table;
  line-height: 1.21428571429;

  @include mq-min-width($bp-palm) {
    background-position: -150px 0; // Adjustments like this follow.
  }

  @include mq-min-width($bp-lap) {
    background-position: 0 0;
  }

  @include mq-min-width($bp-lap-lg) {
    background-blend-mode: normal; // Turn off blending.
    background-color: transparent;
    background-position: -280px 0;
    height: 672px; // Requires height at this point.
  }

  @include mq-min-width($bp-desk) {
    background-position: -250px 0;
  }

  @include mq-min-width($bp-desk-mid) {
    background-position: -170px 0;
  }

  @include mq-min-width(1300px) {
    background-position: -100px 0;
  }

  @include mq-min-width(1500px) {
    background-position: 0 0; // Back to natural full image.
  }



  .home-hero__text {
    background-color: rgba($green-2018, 0.65);
    display: table-cell;
    padding: $grid-spacing-unit;
    text-align: center;
    vertical-align: middle;

    .cssanimations & {
      opacity: 0;
      transition-duration: $opacity-animation-speed;
      transition-property: opacity;

      &.animate {
        opacity: 1;
      }
    }

    @include mq-min-width($bp-lap-lg) {
      background-color: transparent;
      padding-bottom: 0;
      padding-left: 50%; // Centering
      padding-right: $grid-spacing-unit;
      padding-top: 0;
      text-align: left;
      width: 50%;
    }
  }

  h1 {
    color: #fff;
    font-size: 3.25rem;

    @include mq-min-width($bp-lap-lg) {
      font-size: 3.625rem;
    }
  }

  p {
    font-size: 1.5rem;
    color: #fff;

    @include mq-min-width($bp-lap-lg) {
      font-size: 1.75rem;
    }

    @include mq-min-width($bp-desk-lg) {
      padding-right: $grid-spacing-unit * 5;
    }
  }

}
