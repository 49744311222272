// =============================================================================
// Nav used in content area. Styled with circle treatment.
// =============================================================================

// Have to head these rules off with additonal .module class.

.module.nav-deluxe {
  margin-bottom: 1.5rem;

  @include mq-min-width($bp-lap) {
    margin-bottom: 3rem;
  }

  .grid {
    padding-top: 0;

    @include mq-min-width($bp-lap) {
      padding-bottom: 0;
    }

    .grid__item {
      margin-top: 0;
    }

    figure {
      margin-bottom: 0;
    }

    img {
      width: auto;
    }

    &__item {

      @include mq-min-width($bp-lap) {
        margin-bottom: $grid-spacing-unit;
      }
    }
  }

  h2 {
    font-size: 1.4rem;
    margin-bottom: $element-spacing-unit / 4;

    @include mq-min-width($bp-lap-lg) {
      font-size: 1.8rem;
    }
  }
}

// These rules don't need the aggressve cascade overide from .module

.nav-deluxe {

  p {
    font-size: 0.9rem;
  }

  a {
    text-transform: uppercase;
    font-weight: bold;
  }

  &__item {
    position: relative;
  }

  &__text {
    background-color: white;
    padding: 16px 0;

    @include mq-min-width($bp-lap) {
      padding: 16px 32px;
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
    }

    @include mq-min-width($bp-lap-lg) {
      padding: 16px 44px;
      width: 60%;
    }
  }
}
