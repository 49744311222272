// =============================================================================
// Highlight - full width with bg inside modules
// =============================================================================


[class*="highlight-uid-"] {
  padding-bottom: $grid-spacing-unit * 1.5;
  padding-top: $grid-spacing-unit * 1.5;
  position: relative;

  // Shared rules for layering a gradient above the image to provide contrast for text.
  // Individual rule sets under .highlight--dark and .highlight--light.

  &:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; // z-index middle
  }

  h1 {
    font-size: 2rem;

    @include mq-min-width($bp-desk) {
      font-size: 2.5rem;
    }

    @include mq-min-width($bp-desk-mid) {
      font-size: 3.625rem;
    }
  }

  p {
    font-size: 1.25rem;

    @include mq-min-width($bp-desk-lg) {
      font-size: 1.75rem;
    }
  }

  .highlight__text {
    padding-left: $grid-spacing-unit / 2;
    padding-right: $grid-spacing-unit / 2;
    position: relative;
    text-align: center;
    z-index: 3; // z-index top

    & > *:last-child {
      margin: 0;
    }

    @include mq-min-width($bp-lap-lg) {
      padding-left: $grid-spacing-unit;
      padding-right: $grid-spacing-unit;
    }

    @include mq-min-width($bp-desk) {
      padding-bottom: $grid-spacing-unit;
      padding-top: $grid-spacing-unit;
    }

    @include mq-min-width($bp-desk-mid) {
      text-align: left;
    }

    @include mq-min-width($bp-desk-lg) {
      padding-bottom: $grid-spacing-unit * 2.5;
      padding-top: $grid-spacing-unit * 2.5;
    }

  }

  .highlight__ctas {
    list-style-type: none;
    padding-left: 0;

    li {
      display: inline-block;
      margin: 10px 10px 0 0;
    }
  }
}


// =============================================================================
// Highlight variations.
// =============================================================================

// Light: white fade over the image that goes from left to right.

.highlight--light {
  .highlight__text {
    @include mq-min-width($bp-desk-mid) {
      padding-right: 45%;
    }
  }

  // Hello, I make the gradient fade white. My shared properties are above on my parent.

  &:after {
    background-image: radial-gradient(
      circle at 0px 100px,
      #fff,
      #fff 20%,
      rgba(255,255,255,.75) 100%,
      transparent
    );
    left: 0;
  }

  @include mq-min-width($bp-desk-mid) {
    &:after {
      background-image: radial-gradient(
        circle at 0px 100px,
        #fff,
        #fff 20%,
        rgba(255,255,255,.75) 40%,
        transparent
      );
    }
  }

  .no-cssgradients &:after {
    background-color: rgba(255,255,255,.8);
  }
}

// Dark: black fade over the image that goes from right to left.

.highlight--dark {
  background-color: #000;
  color: #bdbdbd;

  h1 {
    color: #fff;
  }

  .highlight__text {
    @include mq-min-width($bp-desk-mid) {
      padding-left: 50%;
    }
  }

  // Hello, I make the gradient fade white. My shared properties are above on my parent.

  &:after {
    background-image: radial-gradient(
      circle at right 100px,
      #000,
      rgba(0,0,0,.4) 100%,
      transparent
    );
    right: 0;
  }

  @include mq-min-width($bp-desk-mid) {
    &:after {
      background-image: radial-gradient(
        circle at right 100px,
        #000,
        rgba(0,0,0,.4) 65%,
        transparent
      );
    }
  }

  .no-cssgradients &:after {
    background-color: rgba(0,0,0,.55);
  }
}


// When it's inside of a grid__item, adjust a few things.

.grid__item [class*="highlight-uid-"] {
  margin-top: $grid-spacing-unit;

  @include mq-min-width($bp-lap) {
    margin-top: $grid-spacing-unit * 2;
  }

  @include mq-min-width($bp-lap-lg) {
    margin-bottom: $grid-spacing-unit * 2;
  }

  @include mq-min-width($bp-desk-mid) {
    margin-left: $grid-spacing-unit;

    .highlight__text {
      text-align: center;
    }

    &.highlight--dark {
      .highlight__text {
        padding-left: $grid-spacing-unit;
      }

      &:after {
        background-image: radial-gradient(
          circle at right 100px,
          #000,
          rgba(0,0,0,.4) 100%,
          transparent
        );
        right: 0;
      }
    }

    &.highlight--light {
      .highlight__text {
        padding-right: $grid-spacing-unit;
      }

      &:after {
        background-image: radial-gradient(
          circle at 0px 100px,
          #fff,
          #fff 20%,
          rgba(255,255,255,.75) 100%,
          transparent
        );
        left: 0;
      }
    }
  }

  @include mq-min-width($bp-desk-lg) {
    .highlight__text {
      padding-bottom: $grid-spacing-unit;
      padding-top: $grid-spacing-unit;
    }
  }
}
