.pagination {
  margin-top: $grid-spacing-unit / 1.25;

  @include mq-min-width($bp-desk) {
    margin-top: $grid-spacing-unit;
  }

  @include mq-min-width($bp-desk-plus) {
    margin-top: $grid-spacing-unit * 1.5;
  }

  p {
    margin: 0;
    text-align: center;
  }

  &__previous,
  &__page,
  &__next {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;

    &:focus {
      outline: none;
    }
  }

  &__previous,
  &__next {
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 4px;
    padding-top: 4px;
    text-transform: uppercase;

    svg {
      height: 16px;
      position: relative;
      top: -1px;
      width: 9px;
    }

    &:hover,
    &:focus {
      color: $teal-2018;
      text-decoration: none;

      path {
        fill: $teal-2018;
      }
    }
  }

  &__previous {
    padding-right: 16px;
  }

  &__next {
    padding-left: 16px;
  }

  &__page {
    color: $green-2018;
    font-family: sans-serif;
    font-weight: 700;
    padding: 5px 10px 4px;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: $green-2018;
      color: #fff;
    }
  }

  &--active {
    background-color: $green-2018;
    color: #fff;
  }

  &--disabled {
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
  }
}
