$module_line_length_short: 20px;
$module_line_length_medium: 32px;
$module_line_length_tall: 44px;

.module-intro {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: $module_line_length_short;
  position: relative;

  @include mq-min-width($bp-lap) {
    padding-bottom: $module_line_length_medium;
  }

  @include mq-min-width($bp-desk-plus) {
    padding-bottom: $module_line_length_tall;
  }

  &::after {
    background-color: rgba(0,0,0,0.3);
    bottom: 0;
    content: '';
    height: $module_line_length_short;
    position: absolute;
    transform: translateX(-1.5px);
    width: 1px;

    @include mq-min-width($bp-lap) {
      height: $module_line_length_medium;
    }

    @include mq-min-width($bp-desk-plus) {
      height: $module_line_length_tall;
    }
  }

  // Tweaks specifically for the triangle-bg instance:
  &--triangle-bg {
    &::after {
      background-color: #f4eee5;
      transform: translate(0.5px, 0);
      width: 2.5px;

      @include mq-min-width($bp-lap) {
        transform: translate(0.5px, -14px);
      }

      @include mq-min-width($bp-desk-plus) {
        height: calc(#{$module_line_length_tall} + 14px);
        transform: translate(-0.5px, 0);
        width: 3px;
      }
    }

    // Add a vertical links to any element that immediately follows the above.
    & + * {
      @include mq-min-width($bp-desk-plus) {
        background-image: linear-gradient(
          to right,
          transparent 0,
          transparent 0.5px,
          #f4eee5 0.5px,
          #f4eee5 3.5px,
          transparent 3.5px,
          transparent 4px
        );
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 4px 100%;
      }
    }
  }

  &__title {
    order: 2;
    z-index: 1;

    h2 {
      color: #555;
      font-family: $font-stack-omnes;
      font-size: (36rem/16);
      font-weight: 500;
      line-height: 1;
      margin-bottom: 0;
      text-align: center;

      @include mq-min-width($bp-lap) {
        font-size: (55rem/16);
      }
    }
  }

  &__subtitle {
    order: 1;

    p {
      color: $green-2018;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 0;
      text-align: center;
      text-transform: uppercase;
    }
  }

  // Background image modifier(s)
  &.module-intro--bridges-bg,
  &.module-intro--pen-bg,
  &.module-intro--triangle-bg {
    background-position: center top;
    background-repeat: no-repeat;

  }

  &.module-intro--bridges-bg {
    background-image: url('/lib/img/ico-bridges-in-circle.png');
    padding-top: 76px;
  }

  &.module-intro--pen-bg {
    background-image: url('/lib/svg/ico-fountain-pen.svg');
    padding-top: 48px;
    background-size: 70px 147px;
  }

  &.module-intro--triangle-bg {
    background-image: url('/lib/svg/ico-bridge-triangle.svg');
    background-size: 254px 136px;
    padding-top: 78px;
  }
}